import axios from "axios"
import {
  GET_EVENTS_REPORT_MANAGEMENT_LIST,
  GET_EVENTS_REPORT_MANAGEMENT_INSTRUCTOR_NUMBERS,
} from "@/helpers/constants/constants"

const apiEventReportManagement = {
  getEventsReportManagementEventsList(params = {}) {
    return axios.get(GET_EVENTS_REPORT_MANAGEMENT_LIST, { params })
  },
  getReportRateNumbers(params = {}) {
    return axios.get(GET_EVENTS_REPORT_MANAGEMENT_INSTRUCTOR_NUMBERS, { params })
  },
  getEventReminderDetails(eventSlug) {
    if (!eventSlug) throw "getEventReminderDetails: received empty slug"
    return axios.get(`${GET_EVENTS_REPORT_MANAGEMENT_LIST}${eventSlug}/reminders/`)
  },
  sendSmsReminder(eventSlug) {
    if (!eventSlug) throw "sendSmsReminder: received empty slug"
    return axios.get(`${GET_EVENTS_REPORT_MANAGEMENT_LIST}${eventSlug}/send_reminder/`)
  }
}

export default apiEventReportManagement
