import Api from "connective/api"
import theme from "@/api/theme"
import i18n from "@/api/i18n"
import mfa from "@/api/mfa"
import gatekeeper from "@/api/gatekeeper"
import gatekeeperProgram from "@/api/gatekeeperProgram"
import gatekeeperEvent from "@/api/gatekeeperEvent"
import gatekeeperInvite from "@/api/gatekeeperInvite"
import gatekeeperSchool from "@/api/gatekeeperSchool"
import gatekeeperOrganization from "@/api/gatekeeperOrganization"
import gatekeeperProgramGroup from "@/api/gatekeeperProgramGroup"
import privateCoordinator from "@/api/privateCoordinator"
import privateVendor from "@/api/privateVendor"
import apiParameters from "@/api/apiParameters"
import apiVendorLessonPlan from "@/api/apiVendorLessonPlan"
import apiEventDashboard from "@/api/apiEventDashboard"
import apiEventReportManagement from "@/api/apiEventReportManagement"
import apiStudent from "@/api/apiStudent"
import apiStudentElective from "@/api/apiStudentElective"
import apiUserManagement from "@/api/apiUserManagement"
import apiOrganizationManagement from "@/api/apiOrganizationManagement"
import apiGoal from "@/api/apiGoal"
import apiHighlights from "@/api/apiHighlights"
import apiReview from "@/api/apiReview"

Api.theme = theme
Api.i18n = i18n
Api.mfa = mfa
Api.gatekeeper = gatekeeper
Api.gatekeeperProgram = gatekeeperProgram
Api.gatekeeperEvent = gatekeeperEvent
Api.gatekeeperInvite = gatekeeperInvite
Api.gatekeeperSchool = gatekeeperSchool
Api.gatekeeperOrganization = gatekeeperOrganization
Api.gatekeeperProgramGroup = gatekeeperProgramGroup
Api.privateCoordinator = privateCoordinator
Api.privateVendor = privateVendor
Api.apiParameters = apiParameters
Api.apiVendorLessonPlan = apiVendorLessonPlan
Api.apiEventDashboard = apiEventDashboard
Api.apiEventReportManagement = apiEventReportManagement
Api.apiStudent = apiStudent
Api.apiStudentElective = apiStudentElective
Api.apiUserManagement = apiUserManagement
Api.apiOrganizationManagement = apiOrganizationManagement
Api.apiGoal = apiGoal
Api.apiHighlights = apiHighlights
Api.apiReview = apiReview

export default Api
