import axios from "axios"

import {
  HIGHLIGHTS_MANAGEMENT_API_URL,
  HIGHLIGHTS_PUBLIC_API_URL,
} from "@/helpers/constants/constants"

const apiHighlights = {

  getHighlightsList(params = {}) {
    return axios.get(HIGHLIGHTS_MANAGEMENT_API_URL, { params })
  },

  createNewHighlights(data) {
    return axios.post(HIGHLIGHTS_MANAGEMENT_API_URL, data)
  },

  updateHighlights(slug, highlights) {
    return axios.put(`${HIGHLIGHTS_MANAGEMENT_API_URL}${slug}`, highlights)
  },

  deleteHighlights(slug) {
    return axios.delete(`${HIGHLIGHTS_MANAGEMENT_API_URL}${slug}/`)
  },

  generateHighlightPublicsShortUrl(slug, longUrl) {
    const params = { url: longUrl }
    return axios.get(`${HIGHLIGHTS_MANAGEMENT_API_URL}${slug}/generate_short_url/`, { params })
  },
  getPublicHighlights(slug, accessToken) {
    const params = { "access_token": accessToken }
    return axios.get(`${HIGHLIGHTS_PUBLIC_API_URL}${slug}/`, { params })
  }

}

export default apiHighlights
