import axios from "axios"
import config from "connective/api/config"

function verifyBaseParams() {
  let urlIsValid = true
  let siteId = null
  let lang = null
  const urlPathTokens = window.location.pathname.split("/")
  if (urlPathTokens.length < 2 || !urlPathTokens[1]) {
    // site is missing - take it from last saved value in local storage
    urlIsValid = false
    siteId = localStorage.getItem("lSid")
  } else {
    siteId = urlPathTokens[1]
  }
  if (!siteId) {
    // still no site id - not in URL and not in local storage - raise an error
    const errorMessage = "URL is invalid, please make sure you use the correct address"
    alert(errorMessage)
    throw Error(errorMessage)
  }
  if (urlPathTokens.length < 3 || !urlPathTokens[2]) {
    // language is missing - take it from last saved value in local storage
    urlIsValid = false
    lang = localStorage.getItem("lLang") || "en" // English as default
  } else {
    lang = urlPathTokens[2]
  }
  if (urlIsValid) {
    // save in local storage for the next time:
    localStorage.setItem("lSid", siteId)
    localStorage.setItem("lLang", lang)
  }
  else {
    // redirect to the correct URL
    window.location.href = `/${siteId}/${lang}/${window.location.search}`
  }
}

function addSiteIdQueryParam() {
  axios.defaults.params = axios.defaults.params || {}
  axios.defaults.params["site_id"] = window.location.pathname.split("/")[1]
}

const connectiveInitSettings = config.initAxiosSettings
config.initAxiosSettings = () => {
  verifyBaseParams()
  addSiteIdQueryParam()
  return connectiveInitSettings()
}

export default config
