import axios from "axios"
import {
  CALL_PRIVATE_VENDOR_INVITE_SMS,
} from "@/helpers/constants/constants"

const privateVendor = {
  sendInviteSMS(slug) {
    const apiURL = CALL_PRIVATE_VENDOR_INVITE_SMS.replace("{slug}", slug)
    return axios.post(apiURL)
  },
}

export default privateVendor
