import axios from "axios"
// import i18n from "@/plugins/i18n"

import {
  GET_ORGANIZATION_MANAGEMENT_API_URL,
} from "@/helpers/constants/constants"
const apiOrganizationManagement = {
  getOrganizationList(params = {}) {
    return axios.get(GET_ORGANIZATION_MANAGEMENT_API_URL, { params })
  },
  getOrganizationDetails(slug) {
    if (!slug) throw "getOrganizationDetails: received empty slug"
    return axios.get(`${GET_ORGANIZATION_MANAGEMENT_API_URL}${slug}/`)
  },
  updateOrganization(slug, data) {
    if (!slug || !data) throw "saveOrganization: received empty slug/data"
    return axios.patch(`${GET_ORGANIZATION_MANAGEMENT_API_URL}${slug}/`, data)
  },
  createOrganization(data) {
    if (!data) throw "createOrganization: received data"
    return axios.post(`${GET_ORGANIZATION_MANAGEMENT_API_URL}`, data)
  },
  deleteOrganization(slug) {
    if (!slug) throw "deleteOrganization: received empty slug"
    return axios.delete(`${GET_ORGANIZATION_MANAGEMENT_API_URL}${slug}/`)
  },
  organizationsArrayToStr(organizationSlugsArray, organizationOptions) {
    return organizationSlugsArray.map(slug => {
      const organizationEntry = organizationOptions.find(option => option.value === slug)
      return organizationEntry ? organizationEntry.text : "Unknown"
    }).join(", ")
  }
}

export default apiOrganizationManagement
