import axios from "axios"
import i18n from "@/plugins/i18n"
import Utils from "@/helpers/utils"

import {
  GET_USER_MANAGEMENT_API_URL,
  GET_USER_MANAGEMENT_EXPORT_API_URL,
  GET_USER_MANAGEMENT_PRIVATE_API_URL,
} from "@/helpers/constants/constants"

const apiUserManagement = {

  getUsersList(params = {}) {
    return axios.get(GET_USER_MANAGEMENT_API_URL, { params })
  },
  getUserDetails(slug) {
    if (!slug) throw "getUserDetails: received empty slug"
    return axios.get(`${GET_USER_MANAGEMENT_API_URL}${slug}/`)
  },
  getUserBrief(slug) {
    if (!slug) throw "getUserBrief: received empty slug"
    return axios.get(`${GET_USER_MANAGEMENT_API_URL}${slug}/brief/`)
  },
  hasAdminScope() {
    return axios.get(`${GET_USER_MANAGEMENT_API_URL}has_admin_scope/`)
  },
  updateUser(slug, data) {
    if (!slug || !data) throw "saveUser: received empty slug/data"
    return axios.patch(`${GET_USER_MANAGEMENT_API_URL}${slug}/update_user/`, data)
  },
  createNewUser(data) {
    if (!data) throw "createNewUser: received data"
    return axios.post(`${GET_USER_MANAGEMENT_API_URL}create_user/`, data)
  },
  addRolesToExistingUserEmail(email, apiRoles) {
    if (!email || !apiRoles) throw "addRolesToExistingUserEmail: received empty email/apiRoles"
    axios.post(`${GET_USER_MANAGEMENT_API_URL}add_roles_to_existing_user_email/`, {
      email,
      roles: apiRoles,
    })
  },
  getUserInvitationUrl(slug) {
    if (!slug) throw "getUserInvitationUrl: received empty slug"
    return axios.get(`${GET_USER_MANAGEMENT_API_URL}${slug}/get_user_invitation_url/`)
  },
  inviteUserByMail(slug) {
    if (!slug) throw "inviteUser: received empty slug"
    return axios.patch(`${GET_USER_MANAGEMENT_API_URL}${slug}/invite_user/`)
  },
  inviteUserBySms(slug) {
    if (!slug) throw "inviteUserBySms: received empty slug"
    return axios.post(`${GET_USER_MANAGEMENT_PRIVATE_API_URL}${slug}/invite_by_sms/`)
  },
  deleteUser(slug) {
    if (!slug) throw "deleteUser: received empty slug"
    return axios.delete(`${GET_USER_MANAGEMENT_API_URL}${slug}/delete_user/`)
  },
  resetPassword(slugsList) {
    const data = { slugs: slugsList.join(",") }
    if (!slugsList) throw "resetPassword: received empty slugs"
    return axios.patch(`${GET_USER_MANAGEMENT_API_URL}reset_password/`, data)
  },
  importUsersFromFile(csvFile, rolesArray, language) {
    const rolesInSnakeCase = Utils.convertKeysCase(rolesArray, "snake")
    const roles = JSON.stringify(rolesInSnakeCase)
    let formData = new FormData()
    formData.append("file", csvFile)
    formData.append("roles", roles)
    formData.append("language", language)
    return axios.post(`${GET_USER_MANAGEMENT_API_URL}import_users_file/`, formData)
  },
  getUsersExportFile(params = {}) {
    // :Object params: query params
    params["page_size"] = 100
    params["page"] = 1
    return axios.get(GET_USER_MANAGEMENT_EXPORT_API_URL, { params })
  },
  getUsersExportTemplateFile() {
    return axios.get(`${GET_USER_MANAGEMENT_EXPORT_API_URL}template/`)
  },
  rolesDisplay(rolesList, organizationOptions) {
    const rolesArray = this.apiRolesToArray(rolesList, organizationOptions)
    return rolesArray
      .map(role => {
        const translationCode = `roleCode.${role.code}`
        const roleStr = i18n.te(translationCode) ? i18n.t(translationCode) : role.code
        return `${roleStr} (${role.organizationsStr})`
      })
      .join(", ")
  },
  apiRolesToArray(rolesList, organizationOptions) {
    // rolesList is a list of role codes as returned by the API, where each role-organization pair is
    // returned as a differenet element in the list. In the UI we show every role with all its organizations.
    // This function converts the API list to array that is more suitable for the UI.
    // example:
    // API: [ROLE_1, ORG_A], [ROLE_1, ORG_B], [ROLE_2, ORG_A], [ROLE_2, ORG_B]
    // this function will convert to:
    // [{code: ROLE_1, organizations: [ORG_A, ORG_B]}, {code: ROLE_2, organizations: [ORG_A, ORG_B]}]
    const rolesMap = {}
    rolesList.forEach(role => {
      if (!rolesMap[role.roleCode]) {
        rolesMap[role.roleCode] = { organizations: [] }
      }
      if (role.all) {
        rolesMap[role.roleCode] = { adminScope: true }
      }
      if (rolesMap[role.roleCode].adminScope !== true) {
        rolesMap[role.roleCode].organizations.push(role.organization)
      }
    })
    const rolesArray = []
    Object.entries(rolesMap).forEach(entry => {
      const [key, value] = entry
      rolesArray.push({
        code: key,
        organizationsArray: value.organizations,
        organizationsStr: value.adminScope === true ? i18n.t("userManagement.allOrganizations") : this.organizationsArrayToStr(value.organizations, organizationOptions),
        all: value.adminScope === true,
      })
    })
    return rolesArray
  },
  organizationsArrayToStr(organizationSlugsArray, organizationOptions) {
    return organizationSlugsArray.map(slug => {
      const organizationEntry = organizationOptions.find(option => option.value === slug)
      return organizationEntry ? organizationEntry.text : "Unknown"
    }).join(", ")
  },
  arrayRolesToApi(rolesList) {
    // converts the roles array to the format expected by the API - opposite to apiRolesToArray()
    const rolesApiArray = []
    rolesList.forEach(roleElement => {
      if (roleElement.all) {
        rolesApiArray.push({ roleCode: roleElement.code, all: true })
      } else {
        roleElement.organizationsArray.forEach(organization => {
          rolesApiArray.push({ roleCode: roleElement.code, organization: organization })
        })
      }
    })
    return rolesApiArray
  },
}

export default apiUserManagement
