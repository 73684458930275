import Api from "@/api"

function getDefaultState() {
  return {
    eventsOpen: [],
    eventsOpenTotal: 0,
    eventsClosed: [],
    eventsClosedTotal: 0,

    totalEventsOpen: 0,
    totalEventsClosed: 0,

    instructorsNumbers: [],
  }
}

function modifyFilterParams(params, fromDate, organizationId, programSlug, search) {
  if (fromDate) {
    params["start_time__gte"] = fromDate
  }
  if (organizationId) {
    params["org_id"] = organizationId
  } else {
    delete params["org_id"]
  }
  if (programSlug) {
    params["school_group__activity_order__activity__slug"] = programSlug
  } else {
    delete params["school_group__activity_order__activity__slug"]
  }
  if (search) {
    params["search"] = search
  } else {
    delete params["search"]
  }

}

const vxEventReportManagement = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_OPEN_EVENTS(state, events) {
      state.eventsOpen = events
    },
    SET_OPEN_EVENTS_COUNT(state, totalCount) {
      state.eventsOpenTotal = totalCount
    },
    SET_CLOSED_EVENTS(state, events) {
      state.eventsClosed = events
    },
    SET_CLOSED_EVENTS_COUNT(state, totalCount) {
      state.eventsClosedTotal = totalCount
    },
    SET_INSTRUCTORS_NUMBERS(state, instructorsNumbers) {
      state.instructorsNumbers = instructorsNumbers
    },
    SET_TOTAL_EVENTS_OPEN(state, totalEventsOpen) {
      state.totalEventsOpen = totalEventsOpen
    },
    SET_TOTAL_EVENTS_CLOSED(state, totalEventsClosed) {
      state.totalEventsClosed = totalEventsClosed
    },
  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },

    async loadOpenEvents({ commit, dispatch, rootGetters }, { fromDate, organizationId, programSlug, search }) {
      const params = rootGetters["pagination/apiParams"]
      params["status"] = "OPEN"
      modifyFilterParams(params, fromDate, organizationId, programSlug, search)
      const res = await Api.apiEventReportManagement.getEventsReportManagementEventsList(params)
      commit("SET_OPEN_EVENTS", res.data.results)
      commit("SET_OPEN_EVENTS_COUNT", res.data.count)
      dispatch("pagination/setTotalServerItems", res.data.count, { root: true })
      return res.data
    },
    async loadClosedEvents({ commit, dispatch, rootGetters }, { fromDate, organizationId, programSlug, search }) {
      const params = rootGetters["pagination2/apiParams"]
      params["status"] = "CLOSED"
      modifyFilterParams(params, fromDate, organizationId, programSlug, search)
      const res = await Api.apiEventReportManagement.getEventsReportManagementEventsList(params)
      commit("SET_CLOSED_EVENTS", res.data.results)
      commit("SET_CLOSED_EVENTS_COUNT", res.data.count)
      dispatch("pagination2/setTotalServerItems", res.data.count, { root: true })
      return res.data
    },
    async loadReportRates({ commit }, { fromDate, organizationId, programSlug, search }) {
      const params = { "aggregation_mode": "true" }
      modifyFilterParams(params, fromDate, organizationId, programSlug, search)
      const res = await Api.apiEventReportManagement.getReportRateNumbers(params)
      commit("SET_INSTRUCTORS_NUMBERS", res.data)
      let totalEventsOpen = 0
      let totalEventsClosed = 0
      res.data.forEach(item => {
        totalEventsOpen += item.numOfOpenEvents
        totalEventsClosed += item.numOfClosedEvents
      })
      commit("SET_TOTAL_EVENTS_OPEN", totalEventsOpen)
      commit("SET_TOTAL_EVENTS_CLOSED", totalEventsClosed)
      return res.data
    },
  },
}
export default vxEventReportManagement
