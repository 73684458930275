import axios from "axios"
import DOMPurify from "dompurify"
import {
  VENDOR_LESSON_PLAN_API_URL,
  VENDOR_LESSON_PLAN_CATALOG_API_URL,
  VENDOR_LESSON_PLAN_SECTION_API_URL,
  VENDOR_LESSON_PLAN_ATTACHMENTS_API_URL,
  VENDOR_LESSON_PLAN_CATALOG_UPDATE_BOOKMARK_API_URL,
} from "@/helpers/constants/constants"

const apiVendorLessonPlan = {

  createCourse() {
    return axios.post(VENDOR_LESSON_PLAN_API_URL, {
      objectType: "COURSE"
    })
  },
  createChildLessonPlan(courseSlug) {
    return axios.post(VENDOR_LESSON_PLAN_API_URL, {
      objectType: "LESSON",
      parentCourse: courseSlug
    })
  },
  createLessonFromAttachment(courseSlug, attachments) {
    const apiUri = `${VENDOR_LESSON_PLAN_API_URL}${courseSlug}/create_lesson_from_attachment/`
    return axios.post(apiUri, attachments)
  },
  getVendorLessonPlan(slug) {
    if (!slug) throw "getVendorLessonPlan: received empty slug"
    return axios.get(`${VENDOR_LESSON_PLAN_API_URL}${slug}/`)
  },
  getMyCourses(params = {}) {
    params.object_type = "COURSE"
    return axios.get(VENDOR_LESSON_PLAN_API_URL, { params })
  },
  getCourseLessonPlans(courseSlug) {
    return axios.get(`${VENDOR_LESSON_PLAN_API_URL}${courseSlug}/lesson_plans/`)
  },
  getLessonPlansCatalog(params = {}) {
    params.object_type = "COURSE"
    return axios.get(VENDOR_LESSON_PLAN_CATALOG_API_URL, { params })
  },
  getLessonPlanFromCatalog(slug) {
    if (!slug) throw "getLessonPlanFromCatalog: received empty slug"
    return axios.get(`${VENDOR_LESSON_PLAN_CATALOG_API_URL}${slug}/`)
  },
  getCourseLessonPlansFromCatalog(courseSlug) {
    return axios.get(`${VENDOR_LESSON_PLAN_CATALOG_API_URL}${courseSlug}/lesson_plans/`)
  },
  updateVendorLessonPlan(slug, data) {
    if (!slug) throw "updateVendorLessonPlan: received empty slug"
    return axios.patch(
      `${VENDOR_LESSON_PLAN_API_URL}${slug}/`,
      data
    )
  },
  deleteLessonPlan(slug) {
    if (!slug) throw "deleteLessonPlan: received empty slug"
    return axios.delete(`${VENDOR_LESSON_PLAN_API_URL}${slug}/`)
  },
  reorderLessons(slug) {
    if (!slug) throw "reorderLessons: received empty slug"
    return axios.patch(`${VENDOR_LESSON_PLAN_API_URL}${slug}/reorder_lessons/`)
  },
  moveLesson(slug, direction) {
    if (!slug) throw "moveLesson: received empty slug"
    if (direction !== "UP" && direction !== "DOWN") throw "moveLesson: received invalid direction"
    return axios.patch(`${VENDOR_LESSON_PLAN_API_URL}${slug}/move_lesson/`, { direction })
  },
  async getVendorLessonPlanSections(lessonPlanSlug) {
    if (!lessonPlanSlug) throw "getVendorLessonPlanSections: received empty lessonPlanSlug"
    const params = { "lesson_plan__slug": lessonPlanSlug }
    let res = await axios.get(VENDOR_LESSON_PLAN_SECTION_API_URL, { params })
    for (const section of res.data.results) {
      section.richText = DOMPurify.sanitize(section.richText, { USE_PROFILES: { html: true } })
    }
    return res
  },
  updateVendorLessonPlanSection(slug, data) {
    if (!slug) throw "updateVendorLessonPlanSection: received empty slug"
    return axios.put(
      `${VENDOR_LESSON_PLAN_SECTION_API_URL}${slug}/`,
      data
    )
  },
  createLessonPlanSection(data) {
    return axios.post(VENDOR_LESSON_PLAN_SECTION_API_URL, data)
  },
  deleteLessonPlanSection(slug) {
    if (!slug) throw "deleteLessonPlanSection: received empty slug"
    return axios.delete(`${VENDOR_LESSON_PLAN_SECTION_API_URL}${slug}/`)
  },
  moveVendorLessonPlanSection(data) {
    return axios.post(
      `${VENDOR_LESSON_PLAN_SECTION_API_URL}move_section_to_index/`,
      data
    )
  },
  getVendorLessonPlanAttachments(lessonPlanSlug) {
    if (!lessonPlanSlug) throw "getVendorLessonPlanAttachments: received empty lessonPlanSlug"
    const apiUrl = VENDOR_LESSON_PLAN_ATTACHMENTS_API_URL.replace("{slug}", lessonPlanSlug)
    return axios.get(apiUrl)
  },
  createLessonPlanAttachment(lessonPlanSlug, data) {
    const apiUrl = VENDOR_LESSON_PLAN_ATTACHMENTS_API_URL.replace("{slug}", lessonPlanSlug)
    return axios.post(apiUrl, data)
  },
  deleteLessonPlanAttachment(lessonPlanSlug, attachmentSlug) {
    let apiUrl = VENDOR_LESSON_PLAN_ATTACHMENTS_API_URL.replace("{slug}", lessonPlanSlug)
    apiUrl = `${apiUrl}${attachmentSlug}/`
    return axios.delete(apiUrl)
  },
  bookmarkLessonPlan(lessonPlanSlug, bookmarkStatus) {
    let apiUrl = VENDOR_LESSON_PLAN_CATALOG_UPDATE_BOOKMARK_API_URL.replace("{slug}", lessonPlanSlug)
    return axios.post(apiUrl, { bookmark: bookmarkStatus })
  },
  getCourseReviewDefinition(courseSlug) {
    const apiUri = `${VENDOR_LESSON_PLAN_CATALOG_API_URL}${courseSlug}/review_definition/`
    return axios.get(apiUri)
  },
  submitCourseReview(courseSlug, reviewAnswers) {
    const apiUri = `${VENDOR_LESSON_PLAN_CATALOG_API_URL}${courseSlug}/review_course/`
    return axios.post(apiUri, { review_answers: reviewAnswers })
  },
  getCourseReviewAnswers(courseSlug) {
    const apiUri = `${VENDOR_LESSON_PLAN_CATALOG_API_URL}${courseSlug}/get_course_review_answers/`
    return axios.get(apiUri)
  },
  getCourseRating(courseSlug) {
    return this.getCoursesRating([courseSlug])
  },
  getCoursesRating(coursesSlugs) {
    const params = { slugs: coursesSlugs.join(",") }
    const apiUri = `${VENDOR_LESSON_PLAN_CATALOG_API_URL}courses_rating/`
    return axios.get(apiUri, { params })
  },
}

export default apiVendorLessonPlan
