import axios from "axios"
import {
  GET_ORGANIZATION_LIST_API_URL,
  GET_GATEKEEPER_SCHOOL_STUDENTS_LIST_API_URL,
  GET_GATEKEEPER_SCHOOL_STUDENTS_BRIEF_LIST_API_URL,
  ASSIGN_BULK_STUDENTS_TO_SCHOOL_ACTIVITY_GROUP,
  UPDATE_GATEKEEPER_USER_SCHOOL_API_URL
} from "@/helpers/constants/constants"

const gatekeeperSchool = {
  getSchoolList(params = {}) {
    return axios.get(GET_ORGANIZATION_LIST_API_URL, { params })
  },
  getSchoolListByPrivilege(privileges) {
    if (!privileges || !privileges.length) {
      throw new Error("getSchoolListByPrivilege must receive privileges list")
    }
    const params = { scope: privileges.join() }
    return axios.get(GET_ORGANIZATION_LIST_API_URL, { params })
  },
  getStudentList(params = {}) {
    return axios.get(GET_GATEKEEPER_SCHOOL_STUDENTS_LIST_API_URL, { params })
  },
  getBriefStudentList(params = {}) {
    return axios.get(GET_GATEKEEPER_SCHOOL_STUDENTS_BRIEF_LIST_API_URL, { params })
  },
  assignStudentsToActivityGroupBulk(slug, usersFile) {
    // :File usersFile: csv or Excel file containing students to assign to the activity group
    let formData = new FormData()
    formData.append("file", usersFile)
    const apiURL = ASSIGN_BULK_STUDENTS_TO_SCHOOL_ACTIVITY_GROUP.replace("{slug}", slug)
    return axios.post(apiURL, formData)
  },
  updateMySchool(schoolSlug) {
    return axios.post(UPDATE_GATEKEEPER_USER_SCHOOL_API_URL, { "schoolSlug": schoolSlug })
  }
}

export default gatekeeperSchool
