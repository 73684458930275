

import axios from "axios"
import {
  GET_ORGANIZATION_LIST_API_URL,
} from "@/helpers/constants/constants"

const gatekeeperOrganization = {
  getOrganizationList(params = {}) {
    return axios.get(GET_ORGANIZATION_LIST_API_URL, { params })
  },
  getOrganizationListByPrivileges(privileges, allowAll, operatorOrgsOnly, consumerOrgsOnly) {
    if (!privileges || !privileges.length) {
      throw new Error("getOrganizationListByPrivileges must receive privileges list")
    }
    const params = { scope: privileges.join() }
    if (operatorOrgsOnly) {
      params["is_operator"] = true
    }
    if (consumerOrgsOnly) {
      params["is_consumer"] = true
    }
    if (allowAll) {
      params["cross_scope"] = true
    }
    return axios.get(GET_ORGANIZATION_LIST_API_URL, { params })
  },
}

export default gatekeeperOrganization
