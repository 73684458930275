import Api from "@/api"

function getDefaultState() {
  return {
    lessonPlan: {},
    lessonPlanSections: [],
    myLessonPlans: [],
    lessonPlanList: [],
    lessonPlanAttachments: [],
    course: {},
    courseLessonPlans: [],
  }
}

const vxVendorLessonPlan = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_LESSON_PLAN(state, lessonPlan) {
      state.lessonPlan = lessonPlan
    },
    SET_LESSON_PLAN_LIST(state, lessonPlanList) {
      state.lessonPlanList = lessonPlanList
    },
    SET_LESSON_PLAN_SECTIONS(state, lessonPlanSections) {
      state.lessonPlanSections = lessonPlanSections
    },
    SET_LESSON_PLAN_SECTION(state, lessonPlanSection) {
      state.lessonPlanSections = [...state.lessonPlanSections, lessonPlanSection]
    },
    UPDATE_LESSON_PLAN_SECTION(state, lessonPlanSection) {
      const index = state.lessonPlanSections.findIndex(section => section.id === lessonPlanSection.id)
      state.lessonPlanSections = [...state.lessonPlanSections.slice(0, index), lessonPlanSection, ...state.lessonPlanSections.slice(index + 1)]
    },
    DELETE_LESSON_PLAN_SECTION(state, slug) {
      state.lessonPlanSections = state.lessonPlanSections.filter(obj => obj.slug !== slug)
    },
    SET_LESSON_PLAN_ATTACHMENTS(state, lessonPlanAttachments) {
      state.lessonPlanAttachments = lessonPlanAttachments
    },
    SET_COURSE(state, course) {
      state.course = course
    },
    SET_COURSE_LESSON_PLANS(state, courseLessonPlans) {
      state.courseLessonPlans = courseLessonPlans
    },
  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async createCourse() {
      const res = await Api.apiVendorLessonPlan.createCourse()
      return res.data.slug
    },
    // eslint-disable-next-line no-unused-vars
    async createChildLesson({ state }, courseSlug) {
      const res = await Api.apiVendorLessonPlan.createChildLessonPlan(courseSlug)
      return res.data.slug
    },
    async loadLessonPlan({ commit, state }, slug) {
      const res = await Api.apiVendorLessonPlan.getVendorLessonPlan(slug)
      commit("SET_LESSON_PLAN", res.data)
      return state.lessonPlan
    },
    async loadCourse({ commit, state }, slug) {
      const res = await Api.apiVendorLessonPlan.getVendorLessonPlan(slug)
      commit("SET_COURSE", res.data)
      return state.course
    },
    async getLessonPlan({ commit, state }, { slug }) {
      if (!state.lessonPlan.slug) {
        this.loadLessonPlan({ commit, state }, { slug })
      }
      return state.data.lessonPlan
    },
    async loadCourseLessonPlans({ commit, state }, { courseSlug }) {
      const res = await Api.apiVendorLessonPlan.getCourseLessonPlans(courseSlug)
      commit("SET_COURSE_LESSON_PLANS", res.data)
      return state.courseLessonPlans
    },
    async updateLessonPlan({ commit, state }, data) {
      const res = await Api.apiVendorLessonPlan.updateVendorLessonPlan(data.slug, data)
      commit("SET_LESSON_PLAN", res.data)
      return state
    },
    async deleteLessonPlan(ctx, slug) {
      await Api.apiVendorLessonPlan.deleteLessonPlan(slug)
    },
    async reorderLessons({ commit, state }, courseSlug) {
      const res = await Api.apiVendorLessonPlan.reorderLessons(courseSlug)
      commit("SET_COURSE_LESSON_PLANS", res.data)
      return state.courseLessonPlans
    },
    async moveLesson({ commit, state }, { lessonSlug, direction }) {
      const res = await Api.apiVendorLessonPlan.moveLesson(lessonSlug, direction)
      commit("SET_COURSE_LESSON_PLANS", res.data)
      return state.courseLessonPlans
    },
    async getMyCourses({ commit, rootGetters, dispatch }) {
      const params = rootGetters["pagination/apiParams"]
      const res = await Api.apiVendorLessonPlan.getMyCourses(params)
      commit("SET_LESSON_PLAN_LIST", res.data.results)
      dispatch("pagination/setTotalServerItems", res.data.count, { root: true })
      return res.data.results
    },
    async loadLessonPlanSections({ commit, state }, lessonPlanSlug) {
      const res = await Api.apiVendorLessonPlan.getVendorLessonPlanSections(lessonPlanSlug)
      commit("SET_LESSON_PLAN_SECTIONS", res.data.results)
      return state.lessonPlanSections
    },
    // move_section_to_index
    async moveLessonPlanSection({ commit, state }, { pk, new_index }) {
      const res = await Api.apiVendorLessonPlan.moveVendorLessonPlanSection({ pk, new_index })
      commit("SET_LESSON_PLAN_SECTIONS", res.data.results)
      return state
    },
    async createLessonPlanSection({ commit }, data) {
      const res = await Api.apiVendorLessonPlan.createLessonPlanSection(data)
      commit("SET_LESSON_PLAN_SECTION", res.data)
      return res.data
    },
    async updateLessonPlanSection({ commit, state }, { slug, data }) {
      const res = await Api.apiVendorLessonPlan.updateVendorLessonPlanSection(slug, data)
      commit("UPDATE_LESSON_PLAN_SECTION", res.data)
      return state
    },
    async deleteLessonPlanSection({ commit }, slug) {
      await Api.apiVendorLessonPlan.deleteLessonPlanSection(slug)
      commit("DELETE_LESSON_PLAN_SECTION", slug)
    },

    async loadLessonPlanAttachments({ commit, state }, lessonPlanSlug) {
      const res = await Api.apiVendorLessonPlan.getVendorLessonPlanAttachments(lessonPlanSlug)
      commit("SET_LESSON_PLAN_ATTACHMENTS", res.data.results)
      return state.lessonPlanAttachments
    },
    // eslint-disable-next-line no-unused-vars
    async createLessonPlanAttachment({ ctx }, { lessonPlanSlug, data }) {
      const res = await Api.apiVendorLessonPlan.createLessonPlanAttachment(lessonPlanSlug, data)
      return res
    },
    // eslint-disable-next-line no-unused-vars
    async deleteLessonPlanAttachment({ ctx }, { lessonPlanSlug, attachmentSlug }) {
      const res = await Api.apiVendorLessonPlan.deleteLessonPlanAttachment(lessonPlanSlug, attachmentSlug)
      return res
    },
  }
}

export default vxVendorLessonPlan
