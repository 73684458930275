import Api from "@/api"

function getDefaultState() {
  return {
    student: [],
    studentGroups: [],
  }
}
const vxStudent = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_STUDENT(state, student) {
      state.student = student
    },
    SET_STUDENT_GROUPS(state, studentGroups) {
      state.studentGroups = studentGroups
    },
  },

  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async loadStudent({ commit, state }, studentSlug) {
      let res = await Api.apiStudent.getStudent(studentSlug)
      commit("SET_STUDENT", res.data)
      return state.student
    },
    async loadStudentGroups({ commit, state }, studentSlug) {
      let res = await Api.apiStudent.getStudentGroups(studentSlug)
      commit("SET_STUDENT_GROUPS", res.data)
      return state.studentGroups
    },
    // eslint-disable-next-line no-unused-vars
    async getStudentReviewDefinitions({ commit }, studentSlug) {
      let res = await Api.apiStudent.getStudentReviewDefinitions(studentSlug)
      return res.data
    },
  },
}

export default vxStudent
