import Api from "@/api"
import Utils from "@/helpers/utils"

function getDefaultState() {
  return {
    usersList: [],
    totalUsers: null,
    organizationOptions: [],
    hasAdminScope: false,
    user: null,
  }
}
const vxUserManagement = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_USERS_LIST(state, usersList) {
      state.usersList = usersList
    },
    SET_USERS_TOTAL(state, total) {
      state.totalUsers = total
    },
    SET_ORGANIZATIONS(state, organizationOptions) {
      state.organizationOptions = organizationOptions
    },
    SET_HAS_ADMIN_SCOPE(state, hasAdminScope) {
      state.hasAdminScope = hasAdminScope
    },
    SET_USER(state, user) {
      state.user = user
    }
  },

  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async loadUsersList({ commit, state, rootGetters, dispatch }, usePagination = true) {
      const params = usePagination ? rootGetters["pagination/apiParams"] : {}
      let res = await Api.apiUserManagement.getUsersList(params)
      commit("SET_USERS_LIST", res.data.results)
      commit("SET_USERS_TOTAL", res.data.count)
      dispatch("pagination/setTotalServerItems", res.data.count, { root: true })
      return state.eventList
    },
    async loadOrganizationOptions({ commit, state }) {
      const organizationsApiRes =
        await Api.gatekeeperOrganization.getOrganizationListByPrivileges(
          ["PRIV_USER_MANAGEMENT_VIEW"],
          false,
          false,
          false,
        )
      const organizationOptions = organizationsApiRes.data["results"].map(organization => {
        return {
          value: organization.slug,
          text: organization.name,
          object: organization,
        }
      })
      commit("SET_ORGANIZATIONS", organizationOptions)
      return state.organizationOptions
    },
    async loadUserScope({ commit, state }) {
      const hasAdminScopeApiRes = await Api.apiUserManagement.hasAdminScope()
      const hasAdminScope = hasAdminScopeApiRes.data
      commit("SET_HAS_ADMIN_SCOPE", hasAdminScope)
      return state.hasAdminScope
    },
    getUsersExportFile({ rootGetters }, { usePagination = true }) {
      const params = usePagination ? rootGetters["pagination/apiParams"] : {}
      Api.apiUserManagement.getUsersExportFile(params).then(res => {
        Utils.downloadTextAsFile("users.csv", res.request.response)
        return res
      })
    },
    getUsersExportTemplateFile() {
      Api.apiUserManagement.getUsersExportTemplateFile().then(res => {
        Utils.downloadTextAsFile("users.csv", res.request.response)
        return res
      })
    },
    async loadUser({ commit, state }, userSlug) {
      const userApiRes = await Api.apiUserManagement.getUserDetails(userSlug)
      const user = userApiRes.data
      commit("SET_USER", user)
      return state.user
    },
  },
}

export default vxUserManagement
