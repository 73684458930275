import axios from "axios"
import {
  POST_MFA_VERIFIED,
  POST_MFA_SEND,
  POST_MFA_VERIFY,
  AUTH_BY_PHONE_INIT,
  AUTH_AUTO_LOGIN,
} from "@/helpers/constants/constants"
import config from "@/api/config"
import { AUTH_BY_PHONE_VERIFY } from "../helpers/constants/constants"


const mfa = {


  isVerified(mfaValue) {
    return axios.post(POST_MFA_VERIFIED, mfaValue)
  },

  send() {
    return axios.post(POST_MFA_SEND)
  },

  verify(passCode) {
    return axios.post(POST_MFA_VERIFY, passCode)
  },
  storedMfaToken() {
    return config.storedMfaToken()
  },
  storeMfaValue(mfaValue) {
    config.storeMfaValue(mfaValue)
    config.configureAxiosMfaHeader(mfaValue)
  },
  sendPhoneAuthenticationInit(phoneNumber, staff) {
    const params = { phoneNumber }
    if (staff) {
      params.staff = true
    }
    return axios.post(AUTH_BY_PHONE_INIT, params)
  },
  phoneAuthenticationVerify(phoneNumber, passCode, schoolSlug, staff, termsAccepted) {
    return axios.post(AUTH_BY_PHONE_VERIFY, { phone_number: phoneNumber, pass_code: passCode, school_slug: schoolSlug, staff: staff, terms_accepted: termsAccepted })
  },
  autoLogin(autoLoginValue) {
    return axios.post(AUTH_AUTO_LOGIN, { auto_login: autoLoginValue })
  }
}

export default mfa
