import Api from "@/api"

function getDefaultState() {
  return {
    schoolList: [],
    studentList: [],
    totalStudents: 0,
  }
}

const gatekeeperSchool = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    ADD_SCHOOLS_TO_LIST(state, schoolList) {
      state.schoolList.push(...schoolList)
    },
    SET_SCHOOL_LIST(state, schoolList) {
      state.schoolList = schoolList
    },
    ADD_STUDENTS_TO_LIST(state, studentList) {
      state.studentList.push(...studentList)
    },
    SET_STUDENT_LIST(state, studentList) {
      state.studentList = studentList
    },
    SET_STUDENTS_TOTAL(state, total) {
      state.totalStudents = total
    },
  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async getSchoolList(
      { commit, state, rootGetters },
      { override = true, usePagination = true }
    ) {
      const params = usePagination ? rootGetters["pagination/apiParams"] : {}
      params.page_size = 200
      const mutation = override ? "SET_SCHOOL_LIST" : "ADD_SCHOOLS_TO_LIST"
      let res = await Api.gatekeeperSchool.getSchoolList(params)
      commit(mutation, res.data.results)
      return state.schoolList
    },
    async getStudentList(
      { commit, state, dispatch, rootGetters },
      { override = true, usePagination = true, useSecondPagination = false, schoolSlug = null, groupSlugToExclude = null }
    ) {
      // :boolean override: whether to override the list or not (i.e., extend)
      let params = {}
      if (usePagination) {
        params = useSecondPagination
          ? rootGetters["pagination2/apiParams"]
          : rootGetters["pagination/apiParams"]
      }
      const mutation = override ? "SET_STUDENT_LIST" : "ADD_STUDENTS_TO_LIST"
      if (schoolSlug) {
        params.organization_member_organization = schoolSlug
      }
      if (groupSlugToExclude) {
        params.exclude_group_slug = groupSlugToExclude
      }
      let res = await Api.gatekeeperSchool.getStudentList(params)
      commit(mutation, res.data.results)
      commit("SET_STUDENTS_TOTAL", res.data.count)

      if (useSecondPagination) {
        dispatch("pagination2/setTotalServerItems", res.data.count, { root: true })
      } else if (usePagination) {
        dispatch("pagination/setTotalServerItems", res.data.count, { root: true })
      }
      return state.studentList
    },
  },
}

export default gatekeeperSchool
