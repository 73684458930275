import Vue from "vue"
import config from "@/api/config"
import Api from "../../api"
import { TOKEN_COOKIE_NAME } from "../../helpers/constants/constants"

function getDefaultState() {
  return {
    isStudent: false, //TODO - this being reset in case of page refresh. Need to fix it
    isAuthenticated: document.cookie.includes(`${TOKEN_COOKIE_NAME}=`),
    requestedRoute: null, // used to store the route that was requested before login
  }
}

const auth = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_AUTH(state, authState) {
      state.isAuthenticated = authState
    },
    SET_IS_STUDENT(state, isStudent) {
      state.isStudent = isStudent
    },
    SET_REQUESTED_ROUTE(state, route) {
      state.requestedRoute = route
    },
    CLEAR_REQUESTED_ROUTE(state) {
      state.requestedRoute = null
    },
  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async login({ commit, dispatch }, { email, password, redirect = true }) {
      let res = await Api.auth.login(email, password)
      Api.config.setToken(res.data.key)
      commit("SET_AUTH", true)
      if (redirect) {
        dispatch("redirectAfterLogin", {})
      }
    },
    async autoLogin({ commit }, { autoLoginValue }) {
      try {
        let res = await Api.mfa.autoLogin(autoLoginValue)
        Api.config.setToken(res.data.key)
        commit("SET_AUTH", true)
        return true
      } catch (e) {
        return false
      }
    },
    async loginByPassCode({ commit, dispatch }, { phoneNumber, passCode, schoolSlug, staff, redirect = true, rememberMe = false, termsAccepted = false }) {
      let res = await Api.mfa.phoneAuthenticationVerify(phoneNumber, passCode, schoolSlug, staff, termsAccepted)
      Api.config.setToken(res.data.key)
      if (rememberMe) {
        Api.config.saveAutoLoginValue(res.data.secret)
      }
      commit("SET_AUTH", true)
      commit("SET_IS_STUDENT", !staff)
      if (redirect) {
        dispatch("redirectAfterLogin", {})
      }
    },
    redirectAfterLogin({ state, commit }, { params = {}, isStudent = false }) {
      const routeRequestedBeforeLogin = state.requestedRoute
      commit("CLEAR_REQUESTED_ROUTE")
      if (routeRequestedBeforeLogin) {
        Vue.$router.push(routeRequestedBeforeLogin).catch(() => { })
      } else {
        if (isStudent) {
          Vue.$router.push({ name: "ConsumerProgramsExplorer", params: params },).catch(() => { })
        } else {
          // in login, a navigartion guard redirect us to the dashboard page, what leads to the error described here:
          // https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
          // we catch the error here to suppress it. Note that alternatively we can fix the navigation guard.
          Vue.$router.push({ name: "Dashboard", params: params },).catch(() => { })
        }
      }
    },
    async logout({ dispatch, state }, redirect = true) {
      const isStudent = state.isStudent
      Api.config.removeToken()
      Api.config.removeAutoLoginValue()
      await dispatch("flushState", null, { root: true })
      if (redirect) {
        // redirect to the login page that was used to login last time:
        if (isStudent) {
          Vue.$router.push({ name: "LoginPhone" })
        } else if (config.storedLoginSmsMethod()) {
          Vue.$router.push({ name: "StaffLoginPhone" })
        } else {
          Vue.$router.push({ name: "Login" })
        }
      }
    },
    async resetPassword(ctx, { uid, token, pass, passConfirm, idNumber }) {
      const res = await Api.auth.resetPassword(uid, token, pass, passConfirm, idNumber)
      return res.data
    },
    createPasswordRecoveryRequest(ctx, { email, recaptchaToken }) {
      return Api.auth.createPasswordRecoveryRequest(email, recaptchaToken)
    },
    storeRequestedRoute({ commit }, route) {
      commit("SET_REQUESTED_ROUTE", route)
    },
  },
}

export default auth
