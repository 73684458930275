import axios from "axios"
import {
  GET_INSTRUCTOR_EVENT_LIST_API_URL,
  GET_INSTRUCTOR_EVENT_API_URL,
  UPDATE_INSTRUCTOR_EVENT_API_URL,
  GET_INSTRUCTOR_SUMMARIES_API_URL,
} from "../helpers/constants/constants"

const instructorEvent = {
  getEvent(slug) {
    if (!slug) throw "getEvent: received empty slug"
    return axios.get(`${GET_INSTRUCTOR_EVENT_API_URL}${slug}/`)
  },
  getInstructorEventSummaryReviewDefinition(slug) {
    if (!slug) throw "  getInstructorEventSummaryReviewDefinition: received empty slug"
    return axios.get(`${GET_INSTRUCTOR_EVENT_API_URL}${slug}/review_definition/`)
  },

  updateEvent(slug, data) {
    if (!slug) throw "updateEvent: received empty slug"
    return axios.patch(`${UPDATE_INSTRUCTOR_EVENT_API_URL}${slug}/`, data)
  },
  getEventList(params = {}) {
    return axios.get(GET_INSTRUCTOR_EVENT_LIST_API_URL, { params })
  },
  summarizeEvent(slug, data) {
    if (!slug) throw "summarizeEvent: received empty slug"
    return axios.patch(`${GET_INSTRUCTOR_SUMMARIES_API_URL}${slug}/`, data)
  },
  getSummaries(params = {}) {
    return axios.get(GET_INSTRUCTOR_SUMMARIES_API_URL, { params })
  },
  getSummariesCounts(params = {}) {
    return axios.get(`${GET_INSTRUCTOR_SUMMARIES_API_URL}counts/`, { params })
  },
  deleteEventPosts(eventSlug) {
    if (!eventSlug) throw "deleteEventPosts: received empty eventSlug"
    return axios.patch(`${UPDATE_INSTRUCTOR_EVENT_API_URL}${eventSlug}/delete_posts/`)
  },
}

export default instructorEvent
