import axios from "axios"

import {
  GET_STUDENT_GROUPS_CATALOG_API_URL,
  GET_STUDENT_MY_ELECTIVE_API_URL,
  GET_ALL_STUDENT_ELECTIVE_CHOICES_API_URL,
} from "@/helpers/constants/constants"

const apiStudentElective = {
  // student APIs (called from student flow - for choosing elective, viewing groups...)
  getGroupsCatalog(params = {}) {
    return axios.get(GET_STUDENT_GROUPS_CATALOG_API_URL, { params })
  },
  getGroup(slug) {
    return axios.get(`${GET_STUDENT_GROUPS_CATALOG_API_URL}${slug}/`)
  },
  getMyElective(params = {}) {
    return axios.get(GET_STUDENT_MY_ELECTIVE_API_URL, { params })
  },
  chooseGroup(groupSlug) {
    const data = { group_slug: groupSlug }
    return axios.post(`${GET_STUDENT_MY_ELECTIVE_API_URL}choose_group/`, data)
  },
  removeSelection(groupSlug) {
    const data = { group_slug: groupSlug }
    return axios.patch(`${GET_STUDENT_MY_ELECTIVE_API_URL}remove_selection/`, data)
  },
  changeElectiveRank(oldRank, newRank) {
    const data = { old_rank: oldRank, new_rank: newRank }
    return axios.patch(`${GET_STUDENT_MY_ELECTIVE_API_URL}change_rank/`, data)
  },
  submitElective() {
    return axios.patch(`${GET_STUDENT_MY_ELECTIVE_API_URL}submit/`)
  },
  // staff APIs (called from staff flow for view/assignment etc.)
  getAllElectiveChoicesList(params = {}) {
    return axios.get(GET_ALL_STUDENT_ELECTIVE_CHOICES_API_URL, { params })
  },
  approveElectiveChoices(choiceSlugs) {
    const data = { slugs: choiceSlugs.join(",") }
    return axios.patch(`${GET_ALL_STUDENT_ELECTIVE_CHOICES_API_URL}approve/`, data)
  },
  rejectElectiveChoices(choiceSlugs) {
    const data = { slugs: choiceSlugs.join(",") }
    return axios.patch(`${GET_ALL_STUDENT_ELECTIVE_CHOICES_API_URL}reject/`, data)
  },
}

export default apiStudentElective
