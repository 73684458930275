export * from "connective/helpers/constants/constants"
import { SERVER, SERVER_URL } from "connective/helpers/constants/constants"

const SERVER_PRIVATE_URL = SERVER_URL.substr(0, SERVER_URL.lastIndexOf("/api")) + "/api_private"
SERVER.userTypes.gatekeeper = "GATEKEEPER"

export { SERVER }
export const GET_THEME_API_URL = `${SERVER_PRIVATE_URL}/theme/`
export const POST_MFA_VERIFIED = `${SERVER_PRIVATE_URL}/auth/mfa/verified/`
export const POST_MFA_SEND = `${SERVER_PRIVATE_URL}/auth/mfa/send/`
export const POST_MFA_VERIFY = `${SERVER_PRIVATE_URL}/auth/mfa/verify/`
export const AUTH_BY_PHONE_INIT = `${SERVER_PRIVATE_URL}/auth/phone/init/`
export const AUTH_BY_PHONE_VERIFY = `${SERVER_URL}/auth/otp_phone_login/`
export const AUTH_AUTO_LOGIN = `${SERVER_URL}/auth/auto_login/`
export const EVENTS_URI = `${SERVER_URL}/events/`
export const UPDATE_GATEKEEPER_PROFILE_API_URL = `${SERVER_PRIVATE_URL}/gatekeepers_profiles/`
export const GET_GATEKEEPER_PROFILE_API_URL = `${SERVER_PRIVATE_URL}/gatekeepers_profiles/me/`
export const GET_GATEKEEPER_PROGRAM_ORDERS_LIST_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_order/`
export const UPDATE_GATEKEEPER_PROGRAM_ORDER_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_order/`
export const GET_GATEKEEPER_COORDINATORS_LIST_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_coordinators/`
export const ADD_GATEKEEPER_COORDINATORS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_coordinators/`
export const EDIT_GATEKEEPER_COORDINATORS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_coordinators/`
export const DELETE_GATEKEEPER_SCHOOL_COORDINATORS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_coordinators/`
export const GET_GATEKEEPER_VENDORS_LIST_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_vendors/`
export const ADD_GATEKEEPER_VENDORS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_vendors/`
export const EDIT_GATEKEEPER_VENDORS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_vendors/`
export const DELETE_GATEKEEPER_SCHOOL_VENDORS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_vendors/`
export const GET_GATEKEEPER_EVENT_LIST_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_event/`
export const GET_GATEKEEPER_EVENT_EXPORT_FILE_API_URL = `${SERVER_PRIVATE_URL}/export_gatekeeper_event/`
export const GET_GATEKEEPER_REPORT_ATTENDANCE_AVG_PER_ACTIVITY = `${SERVER_PRIVATE_URL}/gatekeeper/reports/attendance_average_per_activity/`
export const GET_GATEKEEPER_REPORT_ATTENDANCE_BY_ACTIVITY = `${SERVER_PRIVATE_URL}/gatekeeper/reports/attendance_by_activity/`
export const GET_GATEKEEPER_REPORT_EVENT_SUMMARY_BY_ACTIVITY = `${SERVER_PRIVATE_URL}/gatekeeper/reports/event_summary_by_activity/`
export const GET_GATEKEEPER_SCHOOLS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school/`
export const GET_REPORT_EVENTS_API_URL = `${SERVER_PRIVATE_URL}/reports/events/`
export const GET_REPORT_TOTAL_EVENTS_BY_TAG = `${SERVER_PRIVATE_URL}/reports/events/total_events_by_tag/`
export const GET_REPORT_TOTAL_STUDENT_HOURS_BY_DAY_AND_TAG = `${SERVER_PRIVATE_URL}/reports/events/total_student_hours_by_day_and_tag/`
export const GET_EVENTS_REPORT_MANAGEMENT_LIST = `${SERVER_PRIVATE_URL}/events/report_management/`
export const GET_EVENTS_REPORT_MANAGEMENT_INSTRUCTOR_NUMBERS = `${SERVER_PRIVATE_URL}/events/report_management/reporting_numbers_by_instructor/`
export const UPDATE_GATEKEEPER_USER_SCHOOL_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school/update_my_school/`
export const GET_GATEKEEPER_PROGRAM_GROUPS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group/`
export const UPDATE_GATEKEEPER_PROGRAM_GROUP_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group/`
export const DELETE_GATEKEEPER_PROGRAM_GROUP_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group/`
export const ARCHIVE_GATEKEEPER_PROGRAM_GROUP_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group/{slug}/archive/`
export const GET_GATEKEEPER_PROGRAM_GROUP_CONSUMERS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group/group_consumers/`
export const UPDATE_GATEKEEPER_PROGRAM_GROUP_CONSUMERS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group/`
export const GET_GATEKEEPER_PROGRAM_BRIEF_GROUPS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group_brief/`
export const GET_GATEKEEPER_SCHOOL_STUDENTS_LIST_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_consumers/`
export const GET_GATEKEEPER_SCHOOL_STUDENTS_BRIEF_LIST_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_brief_consumers/`
export const CALL_PRIVATE_COORDINATOR_INVITE_SMS = `${SERVER_PRIVATE_URL}/coordinator_consumers/{slug}/invite_by_sms/`
export const CALL_PRIVATE_VENDOR_INVITE_SMS = `${SERVER_PRIVATE_URL}/vendor_instructors/{slug}/invite_by_sms/`
export const ASSIGN_BULK_STUDENTS_TO_SCHOOL_ACTIVITY_GROUP = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group/{slug}/bulk_assign_consumers/`
export const GET_GROUP_ACTIONS = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group/{slug}/available_actions/`
export const GET_GROUP_EVENT_ATTENDANCE = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group/{slug}/get_event_attendance/`
export const GET_GROUP_EVENT_COSUMER_ATTENDANCE_SUMMARY = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group/{slug}/get_events_consumer_attendance_summary/`
export const GET_GATEKEEPER_PROGRAM_GROUPS_EXPORT_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group_export/`
export const GET_TRANSLATIONS_API_URL = `${SERVER_PRIVATE_URL}/translations/`
export const GET_ORGANIZATION_LIST_API_URL = `${SERVER_PRIVATE_URL}/private_organizations/`
export const GET_ORGANIZATION_MANAGEMENT_API_URL = `${SERVER_PRIVATE_URL}/organization_management/`
export const VENDOR_LESSON_PLAN_API_URL = `${SERVER_PRIVATE_URL}/lesson_plans/`
export const VENDOR_LESSON_PLAN_CATALOG_API_URL = `${SERVER_PRIVATE_URL}/lesson_plans_catalog/`
export const VENDOR_LESSON_PLAN_CATALOG_UPDATE_BOOKMARK_API_URL = `${SERVER_PRIVATE_URL}/lesson_plans_catalog/{slug}/update_bookmark/`
export const VENDOR_LESSON_PLAN_SECTION_API_URL = `${SERVER_PRIVATE_URL}/lesson_plans_sections/`
export const VENDOR_LESSON_PLAN_ATTACHMENTS_API_URL = `${SERVER_PRIVATE_URL}/lesson_plans/{slug}/attachments/`
export const PARAMETERS_API_URL = `${SERVER_PRIVATE_URL}/parameters/`
export const GET_USER_MANAGEMENT_PRIVATE_API_URL = `${SERVER_PRIVATE_URL}/private_user_management/`
export const GOALS_API_URL = `${SERVER_PRIVATE_URL}/goals/`
export const HIGHLIGHTS_MANAGEMENT_API_URL = `${SERVER_PRIVATE_URL}/highlights_management/`
export const HIGHLIGHTS_PUBLIC_API_URL = `${SERVER_PRIVATE_URL}/highlights/`
export const STUDENT_MEASURES_API_URL = `${SERVER_PRIVATE_URL}/student_measures/`


export const LANGUAGES = [
  { value: "he", text: "עברית" },
  { value: "ar", text: "عربيه" },
  { value: "en", text: "English" },
]
export const LANGUAGE_TO_RTL = {
  "he": true,
  "ar": true,
  "en": false,
}

export const HEX_COLOR_PATTERN = "^#[0-9a-fA-F]{6}$"
SERVER.userTypes["gatekeeper"] = "GATEKEEPER"

SERVER["lessonPlanStatus"] = {
  draft: "DRAFT",
  published: "PUBLISHED",
}
