import axios from "axios"
import {
  GET_EVENT_LIST_API_URL,
  GET_EVENT_AVAILABLE_INSTRUCTORS,
  CREATE_EVENT_ORDER_API_URL,
  GET_EVENT_ORDERS_API_URL,
  DELETE_EVENT_ORDER_API_URL,
  DELETE_EVENT_API_URL,
  CALENDAR_CONSTRAINTS_API_URL
} from "../helpers/constants/constants"

const event = {
  getEventList(params = {}) {
    return axios.get(GET_EVENT_LIST_API_URL, { params })
  },
  getEvent(slug) {
    return axios.get(`${GET_EVENT_LIST_API_URL}${slug}/`)
  },
  getProgramInsights(programSlug, params) {
    return axios.get(`${GET_EVENT_LIST_API_URL}${programSlug}/insights/`, { params })
  },
  getAvailableInstructors(eventSlug) {
    if (!eventSlug) throw "getAvailableInstructors: received empty slug"
    const apiUrl = GET_EVENT_AVAILABLE_INSTRUCTORS.replace("{slug}", eventSlug)
    return axios.get(apiUrl)
  },
  createEventOrder(data) {
    return axios.post(CREATE_EVENT_ORDER_API_URL, data)
  },
  uploadEventOrderAttachment(eventOrderSlug, data) {
    if (!eventOrderSlug) throw "uploadEventOrderAttachment: received empty slug"
    return axios.post(`${CREATE_EVENT_ORDER_API_URL}${eventOrderSlug}/add_attachment_to_event/`, data)
  },
  getEventOrders(params = {}) {
    return axios.get(GET_EVENT_ORDERS_API_URL, { params })
  },
  deleteEventOrder(slug) {
    if (!slug) throw "deleteEventOrder: received empty slug"
    return axios.delete(`${DELETE_EVENT_ORDER_API_URL}${slug}/`)
  },
  deleteEvent(slug, includeFuture = false) {
    if (!slug) throw "deleteEvent: received empty slug"
    if (includeFuture) {
      return axios.post(`${DELETE_EVENT_API_URL}${slug}/delete_future_events/`)
    }
    return axios.delete(`${DELETE_EVENT_API_URL}${slug}/`)
  },
  moveEventToEndOfSeries(slug) {
    if (!slug) throw "moveEventToEndOfSeries: received empty slug"
    return axios.patch(`${GET_EVENT_LIST_API_URL}${slug}/move_to_end_of_series/`)
  },
  getExternalCalendarDetails(organizationSlug) {
    if (!organizationSlug) throw "getExternalCalendarDetails: received empty slug"
    const params = { "organization_slug": organizationSlug }
    return axios.get(`${GET_EVENT_LIST_API_URL}external_calendar/`, { params })
  },
  createExternalCalendar(organizationSlug) {
    if (!organizationSlug) throw "createExternalCalendar: received empty slug"
    const params = { "organization_slug": organizationSlug }
    return axios.post(`${GET_EVENT_LIST_API_URL}create_external_calendar/`, null, { params })
  },
  getAttachments(eventSlug) {
    if (!eventSlug) throw "getAttachments: received empty slug"
    return axios.get(`${GET_EVENT_LIST_API_URL}${eventSlug}/attachments/`)
  },
  deleteAttachments(eventSlug, attachmentSlugs) {
    if (!eventSlug) throw "delete_attachments: received empty slug"
    return axios.patch(`${GET_EVENT_LIST_API_URL}${eventSlug}/delete_attachments/`, { attachment_slugs: attachmentSlugs.join(",") })
  },
  addAttachment(eventSlug, data) {
    if (!eventSlug) throw "updateAttachments: received empty slug"
    return axios.post(`${GET_EVENT_LIST_API_URL}${eventSlug}/add_attachment/`, data)
  },
  getCalendarConstraints(params = {}) {
    return axios.get(CALENDAR_CONSTRAINTS_API_URL, { params })
  },
  addCalendarConstraint(data) {
    return axios.post(`${CALENDAR_CONSTRAINTS_API_URL}create_range/`, data)
  },
  updateCalendarConstraint(constraintSlug, data) {
    if (!constraintSlug) throw "updateCalendarConstraint: received empty slug"
    return axios.patch(`${CALENDAR_CONSTRAINTS_API_URL}${constraintSlug}/`, data)
  },
  deleteCalendarConstraint(constraintSlug) {
    if (!constraintSlug) throw "deleteCalendarConstraint: received empty slug"
    return axios.delete(`${CALENDAR_CONSTRAINTS_API_URL}${constraintSlug}/`)
  },
  checkConstraintOverlap(seriesSlug) {
    const params = { series_slug: seriesSlug }
    return axios.get(`${GET_EVENT_LIST_API_URL}check_constraints/`, { params })
  },
  getSeriesSlug(eventOrderSlug) {
    return axios.get(`${GET_EVENT_ORDERS_API_URL}${eventOrderSlug}/get_series_slug/`)
  }
}

export default event
