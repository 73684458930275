import Api from "@/api"

function getDefaultState() {
  return {
    reviewSessionsOpen: [],
    reviewSessionsClosed: [],
  }
}
const vxReviewSessionsManagement = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_REVIEW_SESSIONS_OPEN(state, reviewSessions) {
      state.reviewSessionsOpen = reviewSessions
    },
    SET_REVIEW_SESSIONS_CLOSED(state, reviewSessions) {
      state.reviewSessionsClosed = reviewSessions
    }
  },

  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async loadReviewSessions({ commit, state, rootGetters, dispatch }, { usePagination = true, useSecondaryPagination = false, openReviews, reviewDefinitionSlug, organizationSlug, status, csvMode }) {
      let params = {}
      if (usePagination) {
        params = useSecondaryPagination ? rootGetters["pagination2/apiParams"] : rootGetters["pagination/apiParams"]
      }
      if (reviewDefinitionSlug) {
        params.review_definition__slug = reviewDefinitionSlug
      }
      if (organizationSlug) {
        params.org_slug = organizationSlug
      }
      params.status = status
      if (csvMode) {
        params.csv_mode = true
      }
      let res = await Api.apiReview.getReviewSessionsList(params)
      if (csvMode) {
        return res
      }
      if (!csvMode) {
        const mutation = openReviews ? "SET_REVIEW_SESSIONS_OPEN" : "SET_REVIEW_SESSIONS_CLOSED"
        commit(mutation, res.data.results)
        if (usePagination) {
          const actionName = useSecondaryPagination ? "pagination2/setTotalServerItems" : "pagination/setTotalServerItems"
          dispatch(actionName, res.data.count, { root: true })
        }
        return state.reviewSessions
      } else {
        return res.data.results
      }
    }
  },
}

export default vxReviewSessionsManagement
