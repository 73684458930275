import Api from "@/api"
import Utils from "@/helpers/utils"

function getDefaultState() {
  return {
    groupList: {},
    schoolEditableGroupList: [],
    totalGroups: null,
  }
}

const gatekeeperProgramGroup = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    schoolEditableGroupSlugList(state) {
      return state.schoolEditableGroupList.map(group => group.slug)
    }
  },
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    ADD_GROUPS_TO_LIST(state, groupList) {
      state.groupList.push(...groupList)
    },
    SET_GROUP_LIST(state, groupList) {
      state.groupList = groupList
    },
    SET_GROUPS_TOTAL(state, total) {
      state.totalGroups = total
    },
    SET_SCHOOL_EDITABLE_GROUP_LIST(state, schoolEditableGroupList) {
      state.schoolEditableGroupList = schoolEditableGroupList
    }
  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async getGroup(ctx, groupSlug) {
      let res = await Api.gatekeeperProgramGroup.getGroup(groupSlug)
      return res.data
    },
    async getGroupActions(ctx, groupSlug) {
      let res = await Api.gatekeeperProgramGroup.getGroupActions(groupSlug)
      return res.data
    },

    async getGroupList(
      { commit, state, rootGetters },
      { override = true, usePagination = true }
    ) {
      // :str groupType: which group type to fetch (if empty, fetch all groups)
      // :boolean override: whether to override the groups list or not (i.e., extend)
      const params = usePagination ? rootGetters["pagination/apiParams"] : {}
      if (params.school_slug === Api.gatekeeperProgramGroup.SITE_SCHOOL_CODE) {
        params.activity_order__ownership_type = "SITE"
      }
      else if (params.school_slug) {
        params.consumer_org_slug = params.school_slug
      }
      const mutation = override ? "SET_GROUP_LIST" : "ADD_GROUPS_TO_LIST"
      let res = await Api.gatekeeperProgramGroup.getGroupList(params)
      commit(mutation, res.data.results)
      commit("SET_GROUPS_TOTAL", res.data.count)
      this.dispatch("pagination/setTotalServerItems", res.data.count, { root: true })
      return state.groupList
    },
    async createOrderAndGroup(ctx, data) {
      let res = await Api.gatekeeperProgramGroup.createOrderAndGroup(data)
      return res.data
    },
    async getGroupsByFilter(ctx, params) {
      // get groups by filter and but do not save to store
      let res = await Api.gatekeeperProgramGroup.getGroupList(params)
      return res.data.results
    },
    async updateGroup(ctx, { groupSlug, data }) {
      let res = await Api.gatekeeperProgramGroup.updateGroup(groupSlug, data)
      return res.data
    },
    deleteGroup(ctx, groupSlug) {
      return Api.gatekeeperProgramGroup.deleteGroup(groupSlug)
    },
    async getConsumers(
      { dispatch, rootGetters },
      { groupSlugs, usePagination = true }
    ) {
      // get all consumers under a group
      // :array groupSlug: array of group slugs to fetch consumers by
      const params = usePagination ? rootGetters["pagination/apiParams"] : {}
      let res = await Api.gatekeeperProgramGroup.getConsumers(
        groupSlugs,
        params
      )
      if (usePagination) {
        dispatch("pagination/setTotalServerItems", res.data.count, {
          root: true,
        })
      }
      return res.data.results
    },
    async updateGroupConsumers(ctx, { groupSlug, consumerSlugs }) {
      // override group consumers and move the removed ones to container only
      // :Array consumerSlugs: consumers to apply to group
      let res = await Api.gatekeeperProgramGroup.updateGroupConsumers(
        groupSlug,
        consumerSlugs
      )
      return res.data
    },
    async loadEditableSchoolGroupList({ commit, state }) {
      // group has the "organization" aspects (e.g. name, instructor) and "school" aspects (e.g. students, events).
      // we need to check if the current user has permissions of the "school" aspects, in order to let him to edit
      // students, set events, etc.
      // editableSchools are the schools that the user has "school" permissions for.
      let params = {}
      if (!Utils.hasPrivilege("PRIV_GROUP_MANAGE_ORGANIZATION_EDIT_ALL")) {
        params = { scope: ["PRIV_GROUP_MANAGE_ORGANIZATION_EDIT,PRIV_GROUP_MANAGE_SCHOOL_EDIT"].join() }
      }
      const editableSchools = await Api.gatekeeperSchool.getSchoolList(params)
      const editableSchoolList = editableSchools.data.results
      commit("SET_SCHOOL_EDITABLE_GROUP_LIST", editableSchoolList)
      return state.schoolEditableGroupList
    },
    // eslint-disable-next-line no-unused-vars
    async exportGroupEvents({ state }, { groupSlug, fileName }) {
      Api.gatekeeperProgramGroup.exportGroupEvents(groupSlug).then(res => {
        Utils.downloadTextAsFile(fileName, res.request.response)
        return res
      })
    }
  },
}

export default gatekeeperProgramGroup
