import Api from "@/api"

function getDefaultState() {
  return {}
}

const mfa = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    mfaValue() {
      return Api.mfa.storedMfaToken()
    },
    saveMfaValue(ctx, mfaValue) {
      Api.mfa.storeMfaValue(mfaValue)
    },
    // eslint-disable-next-line no-unused-vars
    async isVerified({ state }, mfaValue) {
      try {
        await Api.mfa.isVerified(mfaValue)
        return true
      } catch (err) {
        return false
      }
    },
    send() {
      return Api.mfa.send()
    },
    async verify(ctx, passCode) {
      return await Api.mfa.verify(passCode)
    }
  },
}

export default mfa
