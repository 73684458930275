import Api from "@/api"

function getDefaultState() {
  return {
    // for student flow (student's "my elective")
    groupsList: [],
    totalGroups: 0,
    elective: null,

    // for staff flow (all students electives)
    electivesChoiceList: [],
    totalElectivesChoices: 0,
  }
}
const vxStudentElective = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    // Student flow
    SET_GROUPS(state, groups) {
      state.groupsList = groups
    },
    ADD_GROUPS(state, groups) {
      state.groupsList.push(...groups)
    },
    SET_GROUPS_COUNT(state, count) {
      state.totalGroups = count
    },
    SET_ELECTIVE(state, elective) {
      state.elective = elective
    },

    // Staff flow
    SET_ELECTIVE_CHOICES_LIST(state, electives) {
      state.electivesChoiceList = electives
    },
    ADD_ELECTIVE_CHOICES_LIST(state, electives) {
      state.electivesChoiceList.push(...electives)
    },
    SET_ELECTIVE_CHOICES_TOTAL(state, total) {
      state.totalElectivesChoices = total
    }
  },

  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    // actions for student flow - called for student API (e.g. choose elective, view groups...)
    async getGroupsCatalog({ commit, rootGetters, dispatch }, { override = true }) {
      const params = rootGetters["pagination/apiParams"]
      const res = await Api.apiStudentElective.getGroupsCatalog(params)
      const mutation = override ? "SET_GROUPS" : "ADD_GROUPS"
      commit(mutation, res.data.results)
      commit("SET_GROUPS_COUNT", res.data.count)
      dispatch("pagination/setTotalServerItems", res.data.count, { root: true })
      return res.data.results
    },
    // eslint-disable-next-line no-unused-vars
    async getGroup({ commit }, slug) {
      const res = await Api.apiStudentElective.getGroup(slug)
      return res.data
    },
    async loadElectives({ commit, }) {
      const res = await Api.apiStudentElective.getMyElective()
      const elective = res.data.results && res.data.results.length > 0 ? res.data.results[0] : null
      commit("SET_ELECTIVE", elective)
      return res.data.results
    },
    // eslint-disable-next-line no-unused-vars
    async chooseGroup({ commit, }, groupSlug) {
      const res = await Api.apiStudentElective.chooseGroup(groupSlug)
      return res.data
    },
    // eslint-disable-next-line no-unused-vars
    async removeSelection({ commit, }, groupSlug) {
      await Api.apiStudentElective.removeSelection(groupSlug)
    },
    // eslint-disable-next-line no-unused-vars
    async changeElectiveRank({ commit, }, { oldRank, newRank }) {
      await Api.apiStudentElective.changeElectiveRank(oldRank, newRank)
    },

    // eslint-disable-next-line no-unused-vars
    async submitElective({ commit, }) {
      await Api.apiStudentElective.submitElective()
    },

    // actions for staff flow - view student elective, assignment, etc.

    async getAllElectiveChoicesList(
      { commit, state, rootGetters },
      { override = true, usePagination = true }
    ) {
      const params = usePagination ? rootGetters["pagination/apiParams"] : {}
      const mutation = override ? "SET_ELECTIVE_CHOICES_LIST" : "ADD_ELECTIVE_CHOICES_LIST"
      let res = await Api.apiStudentElective.getAllElectiveChoicesList(params)
      commit(mutation, res.data.results)
      commit("SET_ELECTIVE_CHOICES_TOTAL", res.data.count)
      this.dispatch("pagination/setTotalServerItems", res.data.count, { root: true })
      return state.electivesChoiceList
    },
  }
}
export default vxStudentElective
