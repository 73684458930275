import axios from "axios"
import {
  GET_PROGRAM_LIST_API_URL,
  GET_PROGRAM_MEDIA_LIST_API_URL,
  GET_SCHOOL_PROGRAM_ORDERS_LIST_API_URL,
  ORDER_SCHOOL_PROGRAM_API_URL,
  GET_TOP_CONSUMER_REQUESTS_STATS_API_URL,
  SERVER,
} from "../helpers/constants/constants"

const program = {
  getProgram(slug) {
    if (!slug) throw "getProgram: received empty slug"
    return axios.get(`${GET_PROGRAM_LIST_API_URL}${slug}/`)
  },
  getProgramMediaList(programSlug) {
    if (!programSlug) throw "getProgramMediaList: received empty slug"
    return axios.get(GET_PROGRAM_MEDIA_LIST_API_URL, {
      params: {
        activity__slug: programSlug,
      },
    })
  },
  getProgramsList(params = {}) {
    // :Object params: query params
    return axios.get(GET_PROGRAM_LIST_API_URL, { params })
  },
  getProgramGroupOrganizations(programSlug, params = {}) {
    if (!programSlug) throw "getProgramGroupOrganizations: received empty programSlug"
    return axios.get(`${GET_PROGRAM_LIST_API_URL}${programSlug}/group_organizations/`, { params })
  },
  getBriefProgramsList(approvedOnly, params = {}) {
    if (approvedOnly) {
      params["approved_only"] = true
    }
    return axios.get(`${GET_PROGRAM_LIST_API_URL}brief/`, { params })
  },
  createProgramOrder(schoolSlug, programSlug) {
    return axios.post(`${ORDER_SCHOOL_PROGRAM_API_URL}?org_requested_for__slug=${schoolSlug}`, {
      org_requested_for: schoolSlug,
      activity: programSlug,
    })
  },
  cancelProgramOrder(schoolSlug, programSlug) {
    return axios.patch(`${ORDER_SCHOOL_PROGRAM_API_URL}${programSlug}/?org_requested_for__slug=${schoolSlug}`, {
      status: SERVER.programOrderStatus.cancelled,
      org_requested_for: schoolSlug
    })
  },
  reCreateProgramOrder(schoolSlug, programSlug) {
    return axios.patch(`${ORDER_SCHOOL_PROGRAM_API_URL}${programSlug}/?org_requested_for__slug=${schoolSlug}`, {
      status: SERVER.programOrderStatus.pendingAdminApproval,
      org_requested_for: schoolSlug
    })
  },
  getOrdersList(params = {}) {
    return axios.get(GET_SCHOOL_PROGRAM_ORDERS_LIST_API_URL, { params })
  },
  getTopConsumerRequestsStats(params = {}) {
    return axios.get(GET_TOP_CONSUMER_REQUESTS_STATS_API_URL, { params })
  },
  getProgramPosts(programSlug, params = {}) {
    if (!programSlug) throw "getProgramPosts: received empty slug"
    return axios.get(`${GET_PROGRAM_LIST_API_URL}${programSlug}/posts/`, { params })
  },
  addPostForProgram(programSlug, data) {
    if (!programSlug) throw "addPostForProgram: received empty programSlug"
    return axios.post(`${GET_PROGRAM_LIST_API_URL}${programSlug}/add_post/`, data)
  },
}

export default program
