import store from "connective/vuex/store"
import theme from "@/vuex/modules/theme"
import i18n from "@/vuex/modules/i18n"
import mfa from "@/vuex/modules/mfa"
import gatekeeper from "@/vuex/modules/gatekeeper"
import gatekeeperProgram from "@/vuex/modules/gatekeeperProgram"
import gatekeeperInvite from "@/vuex/modules/gatekeeperInvite"
import gatekeeperEvent from "@/vuex/modules/gatekeeperEvent"
import gatekeeperSchool from "@/vuex/modules/gatekeeperSchool"
import gatekeeperProgramGroup from "@/vuex/modules/gatekeeperProgramGroup"
import privateCoordinator from "@/vuex/modules/privateCoordinator"
import privateVendor from "@/vuex/modules/privateVendor"
import vxVendorLessonPlan from "@/vuex/modules/vxVendorLessonPlan"
import vxLessonPlanCatalog from "@/vuex/modules/vxLessonPlanCatalog"
import vxEventDashboard from "@/vuex/modules/vxEventDashboard"
import vxEventReportManagement from "@/vuex/modules/vxEventReportManagement"

store.registerModule("theme", theme)
store.registerModule("i18n", i18n)
store.registerModule("mfa", mfa)
store.registerModule("gatekeeper", gatekeeper)
store.registerModule("gatekeeperProgram", gatekeeperProgram)
store.registerModule("gatekeeperInvite", gatekeeperInvite)
store.registerModule("gatekeeperEvent", gatekeeperEvent)
store.registerModule("gatekeeperSchool", gatekeeperSchool)
store.registerModule("gatekeeperProgramGroup", gatekeeperProgramGroup)
store.registerModule("privateCoordinator", privateCoordinator)
store.registerModule("privateVendor", privateVendor)
store.registerModule("vxVendorLessonPlan", vxVendorLessonPlan)
store.registerModule("vxLessonPlanCatalog", vxLessonPlanCatalog)
store.registerModule("vxEventDashboard", vxEventDashboard)
store.registerModule("vxEventReportManagement", vxEventReportManagement)

export default store
