import Api from "@/api"

function getDefaultState() {
  return {
    studentsMeasures: [],
    studentsMeasuresTotal: null,
  }
}
const vxStudentsDashboard = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_STUDENTS_MEASURES(state, studentsMeasures) {
      state.studentsMeasures = studentsMeasures
    },
    SET_STUDENTS_MEASURES_TOTAL(state, total) {
      state.studentsMeasuresTotal = total
    }
  },

  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async loadStudentsMeasures({ commit, state, rootGetters, dispatch }, { usePagination = true }) {
      let params = {}
      if (usePagination) {
        params = rootGetters["pagination/apiParams"]
      }
      if (params.ordering === "name" || params.ordering === "-name") {
        params.ordering = params.ordering.replace("name", "student__name")
      }
      let res = await Api.apiStudent.getStudentsMeasuresList(params)
      commit("SET_STUDENTS_MEASURES", res.data.results)
      commit("SET_STUDENTS_MEASURES_TOTAL", res.data.count)
      dispatch("pagination/setTotalServerItems", res.data.count, { root: true })

      return state.studentsMeasures
    }
  },
}

export default vxStudentsDashboard
