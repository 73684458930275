import Api from "@/api"

function getDefaultState() {
  return {}
}

const privateVendor = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    sendInviteSMS(ctx, slug) {
      return Api.privateVendor.sendInviteSMS(slug)
    },
  },
}

export default privateVendor
