import axios from "axios"

import {
  GOALS_API_URL,
} from "@/helpers/constants/constants"

const apiGoal = {

  getGoalsList(params = {}) {
    return axios.get(GOALS_API_URL, { params })
  },
  getGoalPlanStatus(slug) {
    return axios.get(`${GOALS_API_URL}${slug}/plan_status/`)
  },
  getGoalExecutionStatus(slug) {
    return axios.get(`${GOALS_API_URL}${slug}/execution_status/`)
  }
}

export default apiGoal
