import axios from "axios"
import i18n from "@/plugins/i18n"

import {
  GET_SCHOOL_STUDENTS_LIST_API_URL,
  STUDENT_MEASURES_API_URL,
} from "@/helpers/constants/constants"

const apiStudent = {

  getStudent(slug) {
    if (!slug) throw "getStudent: received empty slug"
    return axios.get(`${GET_SCHOOL_STUDENTS_LIST_API_URL}${slug}/`)
  },
  getStudents(slugsList) {
    if (!slugsList) throw "getStudents: received empty slugsList"
    return axios.get(`${GET_SCHOOL_STUDENTS_LIST_API_URL}?slugs=${slugsList.join(",")}`)
  },
  getStudentGroups(slug) {
    if (!slug) throw "getStudentGroups: received empty slug"
    return axios.get(`${GET_SCHOOL_STUDENTS_LIST_API_URL}${slug}/groups/`)
  },
  getStudentFeedbackedEvents(slug) {
    if (!slug) throw "getStudentFeedbackedEvents: received empty slug"
    return axios.get(`${GET_SCHOOL_STUDENTS_LIST_API_URL}${slug}/feedbacked_events/`)
  },
  addPostForStudent(slug, data) {
    if (!slug) throw "addPostForStudent: received empty slug"
    return axios.post(`${GET_SCHOOL_STUDENTS_LIST_API_URL}${slug}/add_post/`, data)
  },
  getStudentReviewDefinitions(slug) {
    if (!slug) throw "getStudentReviewDefinitions: received empty slug"
    return axios.get(`${GET_SCHOOL_STUDENTS_LIST_API_URL}${slug}/review_definitions/`)
  },
  submitStudentReview(studentSlug, reviewDefinitionSlug, reviewAnswers) {
    const apiUri = `${GET_SCHOOL_STUDENTS_LIST_API_URL}${studentSlug}/submit_review/`
    return axios.post(apiUri, { review_answers: reviewAnswers, review_definition_slug: reviewDefinitionSlug })
  },
  getStudentReviews(slug) {
    if (!slug) throw "getStudentReviews: received empty slug"
    return axios.get(`${GET_SCHOOL_STUDENTS_LIST_API_URL}${slug}/reviews/`)
  },
  getStudentsMeasuresList(params = {}) {
    return axios.get(`${STUDENT_MEASURES_API_URL}`, { params })
  },
  getStudentMeasureDataTimeRange(params = {}) {
    return axios.get(`${STUDENT_MEASURES_API_URL}time_range/`, { params })
  },
  generateStudentReviewSummary(studentMeasuresSlug) {
    return axios.patch(`${STUDENT_MEASURES_API_URL}${studentMeasuresSlug}/generate_review_summary/`)
  },
  gradeDisplay(student) {
    if (!student || !student.profile || !student.profile.grade) return ""
    const grade = i18n.t(`grades.${student.profile.grade}`)
    const classNumber = student.profile.classNumber ? student.profile.classNumber : ""
    if (!classNumber) return grade
    return `${grade} ${classNumber}`

  }
}

export default apiStudent
