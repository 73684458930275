import Api from "@/api"

function getDefaultState() {
  return {
    pastEvents: [],
  }
}

const vxEventDashboard = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    // eslint-disable-next-line no-unused-vars
    async getTotalEventsByTag({ rootGetters }, params) {
      const res = await Api.apiEventDashboard.getTotalEventsByTag(params)
      return res.data
    },

    // eslint-disable-next-line no-unused-vars
    async getTotalStudentHoursByDayAndTag({ rootGetters }, params) {
      const res = await Api.apiEventDashboard.getTotalStudentHoursByDayAndTag(params)
      return res.data
    },
    // eslint-disable-next-line no-unused-vars
    async getEventsMeasures({ rootGetters }, params) {
      const res = await Api.apiEventDashboard.getEventsMeasures(params)
      return res.data
    }
  },
}
export default vxEventDashboard
