import store from "@/vuex/store"
import "@/analytics"
import Vue from "vue"
import vuetify from "@/plugins/vuetify"
import i18n from "@/plugins/i18n"
import cookies from "@/plugins/cookies"
import Api from "@/api"
import "@/filters"
import "@/helpers/validators"
import "intro.js/introjs.css"
import "nprogress/nprogress.css"
import App from "@/App"
import Utils from "@/helpers/utils"
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import VueSocialSharing from "vue-social-sharing"


Vue.use(cookies)
Vue.use(VueSocialSharing)
Vue.config.productionTip = false

Api.config.initAxiosSettings()
async function applyI18n() {
  await store.dispatch("i18n/getTranslations")
  await store.dispatch("i18n/applyTranslations")
}

applyI18n().then(async () => {
  let { default: router } = await import("@/router")
  if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["localhost", "my-site-url.com", /^\//],
        }),
      ],
      tracesSampleRate: 0.2,
    })
  }

  new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount("#app")

  if (Utils.checkRtl()) {
    import("intro.js/introjs-rtl.css")
  }
  // eslint-disable-next-line no-unused-vars
  router.afterEach((to, from) => {
    if (store.state.auth.isAuthenticated) {
      store.dispatch("notification/checkNew")
      store.dispatch("vxPreferences/incrementNumberOfRoutes")
    }
    window.scrollTo(0, 0)
  })

})
