import Api from "@/api"
import i18n from "@/plugins/i18n"
import { HELP_SITE_URL } from "@/helpers/constants/constants"

function getDefaultState() {
  return {
    parameters: {},
    numOfRoutes: 0,
  }
}

const vxPreferences = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_PARAMETERS(state, parameters) {
      state.parameters = parameters
    },
    SET_NUM_OF_ROUTES(state, numOfRoutes) {
      state.numOfRoutes = numOfRoutes
    }
  },
  getters: {
    rolesCodeChoices(state) {
      return state.parameters.roleCodes.map(code => {
        const translationCode = `roleCode.${code}`
        return {
          value: code,
          text: i18n.te(translationCode) ? i18n.t(translationCode) : code
        }
      }).sort((a, b) => a.text.localeCompare(b.text))
    },
  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async getParameters({ commit, state }) {
      let res = await Api.apiParameters.getParameters()
      commit("SET_PARAMETERS", res.data.results[0])
      return state.parameters
    },
    async incrementNumberOfRoutes({ commit, state }) {
      commit("SET_NUM_OF_ROUTES", state.numOfRoutes + 1)
      return state.numOfRoutes
    },
    // eslint-disable-next-line no-unused-vars
    openHelp({ commit, state }) {
      window.open(HELP_SITE_URL, "_hiverr_help")
    },
  },
}
export default vxPreferences
