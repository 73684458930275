import axios from "axios"
import { GET_TRANSLATIONS_API_URL } from "@/helpers/constants/constants"

const i18n = {
  getTranslations(params = {}) {
    return axios.get(GET_TRANSLATIONS_API_URL, {
      params,
      // remove camel case conversion
      transformResponse: axios.defaults.transformResponse.slice(1),
    })
  },
}

export default i18n
