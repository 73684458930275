import Api from "@/api"
import i18nPlugin from "@/plugins/i18n"

const i18n = {
  namespaced: true,
  state: {
    translations: {
      // format: lang: translationObjects
    },
  },
  mutations: {
    ADD_TRANSLATIONS(state, { lang, translations }) {
      state.translations[lang] = translations
    },
  },
  actions: {
    flushState() {
      // do not flush state for translations
      return
    },
    async getTranslations({ commit, state }) {
      const res = await Api.i18n.getTranslations({
        lang: i18nPlugin.locale,
      })
      const translations = res.data
      commit("ADD_TRANSLATIONS", { lang: i18nPlugin.locale, translations })
      return state.translations
    },
    applyTranslations({ state }) {
      const serverTranslations = Object.keys(state.translations)
      for (const lang of serverTranslations) {
        i18nPlugin.setLocaleMessage(lang, state.translations[lang])
      }
      if (!serverTranslations.includes(i18nPlugin.locale)) {
        throw new Error(
          "No serverside translations for language",
          i18nPlugin.locale
        )
      }
    },
  },
}

export default i18n
