import Api from "../../api"
import Utils from "../../helpers/utils"

function getDefaultState() {
  return {
    eventList: [],
    totalEvents: null,
    eventOrders: [],
    programInsights: {},
    eventCreateAnotherScheduling: null,
    calendarConstraints: [],
  }
}

const event = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_MY_SCOPE_SCHOOLS(state, schools) {
      state.myScopeSchools = schools
    },
    SET_PROGRAM_INSIGHTS(state, insights) {
      state.programInsights = insights
    },
    ADD_EVENTS_TO_LIST(state, eventList) {
      state.eventList.push(...eventList)
    },
    SET_EVENTS_LIST(state, eventList) {
      state.eventList = eventList
    },
    SET_EVENTS_TOTAL(state, total) {
      state.totalEvents = total
    },
    SET_EVENT_ORDERS(state, orders) {
      state.eventOrders = orders
    },
    ADD_EVENT_ORDERS(state, orders) {
      state.eventOrders.push(...orders)
    },
    DELETE_EVENT_ORDER(state, slug) {
      state.eventOrders = state.eventOrders.filter(order => order.slug !== slug)
    },
    SET_EVENT_CREATE_ANOTHER_SCHEDULING(state, schedulingObject) {
      state.eventCreateAnotherScheduling = schedulingObject
    },
    CLEAR_EVENT_CREATE_ANOTHER_SCHEDULING(state) {
      state.eventCreateAnotherScheduling = null
    },
    SET_CALENDAR_CONSTRAINTS(state, constraints) {
      state.calendarConstraints = constraints
    },
  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async getEventList(
      { commit, state, rootGetters },
      { startDate, endDate, override = true, usePagination = true, useSecondPagination = false, school = null, myEventsOnly = null, groupSlug = null, useDedicatedPagination = false, consumerSlug = null, programSlug = null, instructorSlug = null }
    ) {
      // :momentObject startDate, endDate: date range to fetch the data
      // :boolean override: whether to override the events list or not (i.e., extend)
      const mutation = override ? "SET_EVENTS_LIST" : "ADD_EVENTS_TO_LIST"

      const startDateString = Utils.dateToApiString(startDate)
      const endDateString = Utils.dateToApiString(endDate)
      let params = {
        start_time__gte: startDateString,
        start_time__lte: endDateString,
      }
      if (usePagination) {
        if (useDedicatedPagination) {
          params = { ...params, ...rootGetters["paginationGroupEvents/apiParams"] }
        }
        else if (useSecondPagination) {
          params = { ...params, ...rootGetters["pagination2/apiParams"] }
        }
        else {
          params = { ...params, ...rootGetters["pagination/apiParams"] }
        }
      }
      if (school) {
        params = { ...params, org_consumer_slug: school }
      }
      if (myEventsOnly) {
        params = { ...params, my_events_only: myEventsOnly }
      }
      if (groupSlug) {
        params = { ...params, school_group__slug: groupSlug }
      }
      if (consumerSlug) {
        params = { ...params, consumer_slug: consumerSlug }
      }
      if (programSlug) {
        params = { ...params, school_group__activity_order__activity__slug: programSlug }
      }
      if (instructorSlug) {
        params = { ...params, instructor__slug: instructorSlug }
      }
      const impersonateUserType = rootGetters["user/isImpersonateUserType"]

      if (impersonateUserType) {
        params["my_school_only"] = true
      }
      let res = await Api.event.getEventList(params)
      commit(mutation, res.data.results)
      commit("SET_EVENTS_TOTAL", res.data.count)
      return state.eventList
    },
    async getProgramInsights(
      { commit, state },
      { programSlug = null, fromDate = null }) {
      const params = { "start_time__gte": fromDate }
      let res = await Api.event.getProgramInsights(programSlug, params)
      commit("SET_PROGRAM_INSIGHTS", res.data)
      return state.programInsights
    },
    async createEventOrder(ctx, data) {
      const res = await Api.event.createEventOrder(data)
      return res.data
    },
    async loadScopedSchool({ commit, state }) {

      const params = { scope: "PRIV_EVENT_VIEW" }
      const results = await Api.gatekeeperSchool.getSchoolList(params)
      const schools = results.data.results
      commit("SET_MY_SCOPE_SCHOOLS", schools)
      return state.myScopeSchools
    },
    async getEventOrders(
      { commit, dispatch, rootGetters },
      { override = true, usePagination = true }
    ) {
      const mutation = override ? "SET_EVENT_ORDERS" : "ADD_EVENT_ORDERS"
      const params = usePagination ? rootGetters["pagination/apiParams"] : {}
      const res = await Api.event.getEventOrders(params)
      commit(mutation, res.data.results)
      dispatch("pagination/setTotalServerItems", res.data.count, { root: true })
      return res.data.results
    },
    async deleteEventOrder({ commit }, slug) {
      await Api.event.deleteEventOrder(slug)
      commit("DELETE_EVENT_ORDER", slug)
    },
    storeEventCreateAnotherScheduling({ commit }, schedulingObject) {
      commit("SET_EVENT_CREATE_ANOTHER_SCHEDULING", schedulingObject)
    },
    clearEventCreateAnotherScheduling({ commit }) {
      commit("CLEAR_EVENT_CREATE_ANOTHER_SCHEDULING")
    },
    async getCalendarConstraints({ commit }, { startDate, endDate }) {
      const res = await Api.event.getCalendarConstraints({
        date__gte: Utils.dateToApiStringNoTime(startDate),
        date__lte: Utils.dateToApiStringNoTime(endDate),
      })
      commit("SET_CALENDAR_CONSTRAINTS", res.data)
      return res.data
    }
  },
}

export default event
