import Api from "@/api"
import Utils from "@/helpers/utils"

function getDefaultState() {
  return {
    reviewSessionsAnswers: [],
    reviewSessionsAnswersTotal: null,
  }
}
const vxReviewSessionsAnswers = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_REVIEW_SESSIONS_ANSWERS(state, reviewSessionsAnswers) {
      state.reviewSessionsAnswers = reviewSessionsAnswers
    },
    SET_REVIEW_SESSIONS_ANSWERS_TOTAL(state, total) {
      state.reviewSessionsAnswersTotal = total
    }
  },

  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async loadReviewSessionsForAnswers({ commit, state, rootGetters, dispatch }, { usePagination, useSecondaryPagination, userSlug, pendingOnly }) {
      let params = {}
      if (useSecondaryPagination) {
        params = rootGetters["pagination2/apiParams"]
      } else if (usePagination) {
        params = rootGetters["pagination/apiParams"]
      }
      const status = pendingOnly ? "PENDING" : ""
      params.user__slug = userSlug
      params.status = status
      let res = await Api.apiReview.getReviewSessionsForAnswersList(params)
      commit("SET_REVIEW_SESSIONS_ANSWERS", res.data.results)
      commit("SET_REVIEW_SESSIONS_ANSWERS_TOTAL", res.data.count)
      if (useSecondaryPagination) {
        dispatch("pagination2/setTotalServerItems", res.data.count, { root: true })
      } else if (usePagination) {
        dispatch("pagination/setTotalServerItems", res.data.count, { root: true })
      }

      return state.reviewSessions
    },
    // eslint-disable-next-line no-unused-vars
    async getMyReviewSessionsCounts({ state }) {
      let params = {}
      params.user__slug = Utils.myUserSlug()
      let res = await Api.apiReview.getMyReviewSessionsCounts(params)
      return res.data
    },
  },
}

export default vxReviewSessionsAnswers
