import Api from "@/api"

function getDefaultState() {
  return {
    goalsList: [],
  }
}
const vxGoal = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_GOAL_LIST(state, goalsList) {
      state.goalsList = goalsList
    },
  },

  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },

    async loadGoalsList({ commit, state }, { params }) {
      const goalsApiRes = await Api.apiGoal.getGoalsList(params)
      const goalsList = goalsApiRes.data["results"]
      commit("SET_GOAL_LIST", goalsList)
      return state.goalsList
    },
  },
}

export default vxGoal
