import Api from "../../api"

function getDefaultState() {
  return {
    groupList: [],
    totalGroups: null,
    selectedSchoolSlug: "",
  }
}

const programGroup = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    ADD_GROUPS_TO_LIST(state, groupList) {
      state.groupList.push(...groupList)
    },
    SET_GROUPS_LIST(state, groupList) {
      state.groupList = groupList
    },
    SET_GROUPS_TOTAL(state, total) {
      state.totalGroups = total
    },
    SET_SELECTED_SCHOOL(state, schoolSlug) {
      state.selectedSchoolSlug = schoolSlug
    }
  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async getGroup(ctx, groupSlug) {
      let res = await Api.programGroup.getGroup(groupSlug)
      return res.data
    },

    async getGroupList(
      { commit, state, rootGetters, dispatch },
      { groupType, override = true, usePagination = true, useSecondaryPagination = false, briefActivityOnly = false, orgConsumerId = null, anyOrgSlug = null, availableForInstructorSlug = null, instructorSlug = null }
    ) {
      // :str groupType: which group type to fetch (if empty, fetch all groups)
      // :boolean override: whether to override the groups list or not (i.e., extend)
      let params = usePagination ? rootGetters["pagination/apiParams"] : {}
      if (useSecondaryPagination) {
        params = rootGetters["pagination2/apiParams"]
      }
      if (groupType) {
        params.group_type = groupType
      }
      if (briefActivityOnly) {
        // for returning brief results with group name/slug and activity name/slug only
        params.brief_activity_only = true
      }
      if (orgConsumerId) {
        params.org_consumer__id = orgConsumerId
      }
      if (anyOrgSlug) {
        params.org_slug = anyOrgSlug
      }
      if (instructorSlug) {
        params.instructor__slug = instructorSlug
      }
      if (availableForInstructorSlug) {
        params.available_for_instructor_slug = availableForInstructorSlug
      }
      const mutation = override ? "SET_GROUPS_LIST" : "ADD_GROUPS_TO_LIST"
      let res = await Api.programGroup.getGroupList(params)
      commit(mutation, res.data.results)
      commit("SET_GROUPS_TOTAL", res.data.count)
      if (useSecondaryPagination) {
        dispatch("pagination2/setTotalServerItems", res.data.count, {
          root: true,
        })
      } else if (usePagination) {
        dispatch("pagination/setTotalServerItems", res.data.count, {
          root: true,
        })
      }
      return state.groupList
    },
    async getGroupsByFilter(ctx, params) {
      let res = await Api.programGroup.getGroupList(params)
      return res.data.results
    },
    async createGroup(ctx, data) {
      let res = await Api.programGroup.createGroup(data)
      return res.data
    },
    async updateGroup(ctx, { groupSlug, data }) {
      let res = await Api.programGroup.updateGroup(groupSlug, data)
      return res.data
    },
    deleteGroup(ctx, groupSlug) {
      return Api.programGroup.deleteGroup(groupSlug)
    },
    async getConsumers(
      { dispatch, rootGetters },
      { groupSlugs, usePagination = true }
    ) {
      // get all consumers under a group
      // :array groupSlug: array of group slugs to fetch consumers by
      const params = usePagination ? rootGetters["pagination/apiParams"] : {}
      let res = await Api.programGroup.getConsumers(groupSlugs, params)
      if (usePagination) {
        dispatch("pagination/setTotalServerItems", res.data.count, {
          root: true,
        })
      }
      return res.data.results
    },
    async updateGroupConsumers(ctx, { groupSlug, consumerSlugs }) {
      // override group consumers and move the removed ones to container only
      // :Array consumerSlugs: consumers to apply to group
      let res = await Api.programGroup.updateGroupConsumers(
        groupSlug,
        consumerSlugs
      )
      return res.data
    },
    setSelectedSchoolSlug({ state, commit }, schoolSlug) {
      commit("SET_SELECTED_SCHOOL", schoolSlug)
      return state.selectedSchoolSlug
    },
  },
}

export default programGroup
