import Api from "@/api"

function getDefaultState() {
  return {
    organizationList: [],
    totalOrganizations: null,
  }
}
const vxOrganizationManagement = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_ORGANIZATIONS_LIST(state, organizationList) {
      state.organizationList = organizationList
    },
    SET_ORGANIZATIONS_TOTAL(state, total) {
      state.totalOrganizations = total
    }
  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async loadOrganizationList({ commit, state, rootGetters, dispatch }, usePagination = true) {
      const params = usePagination ? rootGetters["pagination/apiParams"] : {}
      let res = await Api.apiOrganizationManagement.getOrganizationList(params)
      commit("SET_ORGANIZATIONS_LIST", res.data.results)
      commit("SET_ORGANIZATIONS_TOTAL", res.data.count)
      dispatch("pagination/setTotalServerItems", res.data.count, { root: true })
      return state.eventList
    },
  },
}

export default vxOrganizationManagement
