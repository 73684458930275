import axios from "axios"
import {
  CREATE_FEED_POST_API_URL,
  CREATE_POST_IMAGES_API_URL,
} from "../helpers/constants/constants"

const eventFeedPost = {
  createFeedPost(data) {
    return axios.post(`${CREATE_FEED_POST_API_URL}`, data)
  },
  createPostImages(data) {
    return axios.post(`${CREATE_POST_IMAGES_API_URL}`, data)
  },
  getFeedPosts(params = {}) {
    return axios.get(`${CREATE_FEED_POST_API_URL}`, { params })
  },
  getFeedPost(postSlug) {
    return axios.get(`${CREATE_FEED_POST_API_URL}${postSlug}/`)
  },
  updateLike(slug, likeStatus) {
    return axios.post(`${CREATE_FEED_POST_API_URL}${slug}/update_like/`, { like_status: likeStatus })
  },
  addComment(slug, comment) {
    return axios.post(`${CREATE_FEED_POST_API_URL}${slug}/add_comment/`, { comment_text: comment })
  },
  updateBookmark(slug, bookmarkStatus) {
    return axios.post(`${CREATE_FEED_POST_API_URL}${slug}/update_bookmark/`, { bookmark: bookmarkStatus })
  },
}

export default eventFeedPost
