import Api from "@/api"

function getDefaultState() {
  return {
    lessonPlanCatalogList: [],
    lessonPlanCatalogCount: 0,
  }
}

const vxLessonPlanCatalog = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_LESSON_PLAN_CATALOG_LIST(state, lessonPlanList) {
      state.lessonPlanCatalogList = lessonPlanList
    },
    ADD_LESSON_PLAN_CATALOG_LIST(state, lessonPlanList) {
      state.lessonPlanCatalogList.push(...lessonPlanList)
    },
    SET_LESSON_PLAN_CATALOG_COUNT(state, count) {
      state.lessonPlanCatalogCount = count
    }

  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async getLessonPlansCatalog({ commit, rootGetters, dispatch }, { override = true }) {
      const params = rootGetters["pagination/apiParams"]
      const res = await Api.apiVendorLessonPlan.getLessonPlansCatalog(params)
      const mutation = override ? "SET_LESSON_PLAN_CATALOG_LIST" : "ADD_LESSON_PLAN_CATALOG_LIST"
      commit(mutation, res.data.results)
      commit("SET_LESSON_PLAN_CATALOG_COUNT", res.data.count)
      dispatch("pagination/setTotalServerItems", res.data.count, { root: true })
      return res.data.results
    },
    // eslint-disable-next-line no-unused-vars
    async loadLessonPlan({ commit, state }, slug) {
      const res = await Api.apiVendorLessonPlan.getLessonPlanFromCatalog(slug)
      return res.data
    },
    // eslint-disable-next-line no-unused-vars
    async loadLessonPlanSections({ commit, state }, lessonPlanSlug) {
      const res = await Api.apiVendorLessonPlan.getVendorLessonPlanSections(lessonPlanSlug)
      return res.data.results
    },
    // eslint-disable-next-line no-unused-vars
    async getCourseReviewDefinition({ commit, state }, courseSlug) {
      const res = await Api.apiVendorLessonPlan.getCourseReviewDefinition(courseSlug)
      return res.data
    }
  }
}

export default vxLessonPlanCatalog
