import Api from "@/api"

function getDefaultState() {
  return {
    reviewDefinitions: [],
    reviewDefinitionsTotal: null,
  }
}
const vxReviewDefinition = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_REVIEW_DEFINITIONS(state, reviewDefinitions) {
      state.reviewDefinitions = reviewDefinitions
    },
    SET_REVIEW_DEFINITIONS_TOTAL(state, total) {
      state.reviewDefinitionsTotal = total
    }
  },

  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async loadReviewDefinitions({ commit, state, rootGetters, dispatch }, { usePagination = true }) {
      let params = {}
      if (usePagination) {
        params = rootGetters["pagination/apiParams"]
      }
      let res = await Api.apiReview.getReviewDefinitionsList(params)
      commit("SET_REVIEW_DEFINITIONS", res.data.results)
      commit("SET_REVIEW_DEFINITIONS_TOTAL", res.data.count)
      dispatch("pagination/setTotalServerItems", res.data.count, { root: true })

      return state.reviewDefinitions
    }
  },
}

export default vxReviewDefinition
