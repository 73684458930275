import axios from "axios"
import {
  PARAMETERS_API_URL,
} from "@/helpers/constants/constants"

const apiParameters = {

  getParameters() {
    return axios.get(PARAMETERS_API_URL)
  },

}

export default apiParameters
