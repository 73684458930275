import axios from "axios"

import {
  REVIEW_DEFINITION_API_URL,
  REVIEW_SESSION_MANAGEMENT_API_URL,
  REVIEW_SESSION_ANSWER_API_URL,
  SURVEY_PUBLIC_API_URL,

} from "@/helpers/constants/constants"

const apiReview = {

  getReviewDefinitionsList(params = {}) {
    return axios.get(REVIEW_DEFINITION_API_URL, { params })
  },
  getReviewDefinition(slug) {
    return axios.get(`${REVIEW_DEFINITION_API_URL}${slug}/`)
  },
  getAllReviewDefinitions(params = {}) {
    return axios.get(`${REVIEW_DEFINITION_API_URL}all_reviews/`, { params })
  },
  saveReviewDefinition(reviewDefinitionSlug, data) {
    return axios.patch(`${REVIEW_DEFINITION_API_URL}${reviewDefinitionSlug}/`, data)
  },
  deleteReviewSession(reviewSessionSlug) {
    return axios.delete(`${REVIEW_SESSION_MANAGEMENT_API_URL}${reviewSessionSlug}/`)
  },
  distributeToGroupInstructors(reviewSlug, groupsSlugs) {
    return axios.post(`${REVIEW_DEFINITION_API_URL}${reviewSlug}/distribute_to_group/`, { groupsSlugs: groupsSlugs.join(",") })
  },
  autoDistributeReviewDefinition(reviewSlug) {
    return axios.post(`${REVIEW_DEFINITION_API_URL}${reviewSlug}/auto_distribute/`)
  },
  generatePendingReviewsShortUrl(slug, originalUrl) {
    const params = { url: originalUrl }
    return axios.get(`${REVIEW_DEFINITION_API_URL}${slug}/generate_short_url/`, { params })
  },
  getReviewSessionsList(params = {}) {
    return axios.get(REVIEW_SESSION_MANAGEMENT_API_URL, { params })
  },
  getReviewSessionsSummary(params = {}) {
    params.minimal = true
    return axios.get(`${REVIEW_SESSION_MANAGEMENT_API_URL}summary/`, { params })
  },
  getReviewSessionsForAnswersList(params = {}) {
    return axios.get(REVIEW_SESSION_ANSWER_API_URL, { params })
  },
  getMyReviewSessionsCounts(params = {}) {
    return axios.get(`${REVIEW_SESSION_ANSWER_API_URL}counts/`, { params })
  },
  getReviewDefinitionOfSession(reviewSessionSlug) {
    return axios.get(`${REVIEW_SESSION_ANSWER_API_URL}${reviewSessionSlug}/definition/`)
  },
  getReviewSessionForAnswer(reviewSessionSlug) {
    return axios.get(`${REVIEW_SESSION_ANSWER_API_URL}${reviewSessionSlug}/`)
  },
  saveReviewSessionsAnswers(reviewSessionSlug, answers) {
    return axios.post(`${REVIEW_SESSION_ANSWER_API_URL}${reviewSessionSlug}/submit_review/`, { reviewAnswers: answers })
  },
  getSurveyReviewDefinition(reviewDefinitionSlug) {
    return axios.get(`${SURVEY_PUBLIC_API_URL}${reviewDefinitionSlug}/`)
  },
  submitSurvey(surveySlug, programSlug, surveyAnswers) {
    return axios.post(`${SURVEY_PUBLIC_API_URL}${surveySlug}/submit_survey/`, { surveyAnswers, programSlug })
  },
  isTypeStudentByStaff(typeStr) {
    return typeStr === "STUDENT_FEEDBACK_BY_STAFF"
  },
  isTypeGroupByStudent(typeStr) {
    return typeStr === "GROUP_REVIEW_BY_STUDENT"
  },
  isTypeProgramSurvey(typeStr) {
    return typeStr === "PROGRAM_SURVEY"
  },
}

export default apiReview
