import Api from "@/api"

function getDefaultState() {
  return {
    programOrders: [],
  }
}

const gatekeeperProgram = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_PROGRAM_ORDERS(state, orders) {
      state.programOrders = orders
    },
    UPDATE_PROGRAM_ORDER(state, order) {
      const filteredOrder = state.programOrders.filter(
        o => o.slug === order.slug
      )[0]
      Object.assign(filteredOrder, order)
    },
  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async getProgramOrders({ commit, rootGetters, dispatch }) {
      const params = rootGetters["pagination/apiParams"]
      const res = await Api.gatekeeperProgram.getProgramOrders(params)
      commit("SET_PROGRAM_ORDERS", res.data.results)
      dispatch("pagination/setTotalServerItems", res.data.count, { root: true })
      return res.data.results
    },
    async updateProgramOrder({ commit }, { slug, data }) {
      const res = await Api.gatekeeperProgram.updateProgramOrder(slug, data)
      commit("UPDATE_PROGRAM_ORDER", res.data)
      return res.data
    },
  },
}

export default gatekeeperProgram
