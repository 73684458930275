import axios from "axios"
import {
  SERVER,
  GET_GATEKEEPER_PROGRAM_GROUPS_API_URL,
  GET_GATEKEEPER_PROGRAM_GROUPS_EXPORT_API_URL,
  GET_GATEKEEPER_PROGRAM_BRIEF_GROUPS_API_URL,
  UPDATE_GATEKEEPER_PROGRAM_GROUP_API_URL,
  ARCHIVE_GATEKEEPER_PROGRAM_GROUP_API_URL,
  DELETE_GATEKEEPER_PROGRAM_GROUP_API_URL,
  GET_GATEKEEPER_PROGRAM_GROUP_CONSUMERS_API_URL,
  UPDATE_GATEKEEPER_PROGRAM_GROUP_CONSUMERS_API_URL,
  GET_GROUP_ACTIONS,
  GET_GROUP_EVENT_ATTENDANCE,
  GET_GROUP_EVENT_COSUMER_ATTENDANCE_SUMMARY,
  EVENTS_URI,
} from "@/helpers/constants/constants"

const gatekeeperProgramGroup = {
  SITE_SCHOOL_CODE: "SITE_SCHOOL_CODE",
  getGroup(groupSlug) {
    if (!groupSlug) throw "getGroup: received empty slug"
    return axios.get(`${GET_GATEKEEPER_PROGRAM_GROUPS_API_URL}${groupSlug}/`)
  },
  getGroupActions(groupSlug) {
    if (!groupSlug) { throw "getGroupActions: received empty slug" }
    const apiURL = GET_GROUP_ACTIONS.replace("{slug}", groupSlug)
    return axios.get(apiURL)
  },
  getGroupAvailableInstructors(groupSlug) {
    if (!groupSlug) { throw "getGroupAvailableInstructors: received empty slug" }
    const apiURL = `${GET_GATEKEEPER_PROGRAM_GROUPS_API_URL}${groupSlug}/available_instructors/`
    return axios.get(apiURL)
  },
  getEventsInstructorReviewAnswers(groupSlug) {
    if (!groupSlug) { throw "getEventsInstructorReviewAnswers: received empty slug" }
    const apiURL = `${GET_GATEKEEPER_PROGRAM_GROUPS_API_URL}${groupSlug}/get_events_instructor_event_review_answers/`
    return axios.get(apiURL)
  },
  getGroupList(params = {}, programSlug = null) {
    if (!params.allow_archived) {
      params.status = SERVER.groupStatus.active
    }
    if (!programSlug) {
      return axios.get(GET_GATEKEEPER_PROGRAM_GROUPS_API_URL, { params })
    }
    params = { ...params, activity_order__activity__slug: programSlug }
    return axios.get(GET_GATEKEEPER_PROGRAM_GROUPS_API_URL, { params })
  },
  getBriefGroupList(params = {}) {
    params.status = SERVER.groupStatus.active
    return axios.get(GET_GATEKEEPER_PROGRAM_BRIEF_GROUPS_API_URL, { params })
  },
  exportGroupEvents(groupSlug) {
    if (!groupSlug) { throw "exportEvents: received empty slug" }
    const apiURL = `${GET_GATEKEEPER_PROGRAM_GROUPS_EXPORT_API_URL}`
    const params = { group_slug: groupSlug }
    return axios.get(apiURL, { params })
  },
  updateGroup(groupSlug, data) {
    if (!groupSlug) throw "updateGroup: received empty slug"
    return axios.patch(
      `${UPDATE_GATEKEEPER_PROGRAM_GROUP_API_URL}${groupSlug}/`,
      data
    )
  },
  archiveGroup(groupSlug) {
    if (!groupSlug) throw "archiveGroup: received empty slug"
    const apiURL = ARCHIVE_GATEKEEPER_PROGRAM_GROUP_API_URL.replace("{slug}", groupSlug)
    return axios.post(apiURL)
  },
  archiveGroups(groupSlugs) {
    const slugs = groupSlugs.join(",")
    const params = { slugs: slugs }
    return axios.get(`${GET_GATEKEEPER_PROGRAM_GROUPS_API_URL}archive_groups/`, { params })
  },
  deleteGroup(groupSlug) {
    if (!groupSlug) throw "deleteGroup: received empty slug"
    return axios.delete(
      `${DELETE_GATEKEEPER_PROGRAM_GROUP_API_URL}${groupSlug}/`
    )
  },
  updateGroupsLock(groupSlugs, lockStatus) {
    const slugs = groupSlugs.join(",")
    const params = { slugs: slugs, lock_status: lockStatus }
    return axios.get(`${GET_GATEKEEPER_PROGRAM_GROUPS_API_URL}update_lock/`, { params })
  },
  createPastEvent(groupSlug, data) {
    if (!groupSlug) throw "createPastEvent: received empty slug"
    const apiURL = `${GET_GATEKEEPER_PROGRAM_GROUPS_API_URL}${groupSlug}/create_past_event/`
    return axios.post(apiURL, data)
  },
  async exportGroupsAttendance(groupSlugs) {
    const slugs = groupSlugs.join(",")
    const params = { slugs: slugs }
    return axios.get(`${GET_GATEKEEPER_PROGRAM_GROUPS_API_URL}export_attendance/`, { params, responseType: "blob" })
  },
  getConsumers(groupSlugs, params) {
    if (!groupSlugs) throw "getConsumers: received empty slug"
    return axios.get(GET_GATEKEEPER_PROGRAM_GROUP_CONSUMERS_API_URL, {
      params: { ...params, slugs: groupSlugs.join(",") },
    })
  },
  updateGroupConsumers(groupSlug, consumerSlugs) {
    if (!groupSlug) {
      throw "updateGroupConsumers: received empty slug"
    }
    return axios.patch(
      `${UPDATE_GATEKEEPER_PROGRAM_GROUP_CONSUMERS_API_URL}${groupSlug}/update_group_consumers/`,
      consumerSlugs
    )
  },
  addConsumersToGroup(groupSlug, consumerSlugs) {
    if (!groupSlug) {
      throw "addConsumersToGroup: received empty slug"
    }
    return axios.patch(
      `${UPDATE_GATEKEEPER_PROGRAM_GROUP_CONSUMERS_API_URL}${groupSlug}/add_consumers/`,
      consumerSlugs
    )
  },
  removeConsumersFromGroup(groupSlug, consumerSlugs) {
    if (!groupSlug) {
      throw "removeConsumersFromGroup: received empty slug"
    }
    return axios.patch(
      `${UPDATE_GATEKEEPER_PROGRAM_GROUP_CONSUMERS_API_URL}${groupSlug}/remove_consumers/`,
      consumerSlugs
    )
  },
  createOrderAndGroup(data) {
    return axios.post(
      `${GET_GATEKEEPER_PROGRAM_GROUPS_API_URL}create_order_and_group/`,
      data
    )
  },
  getEventsAttendance(groupSlug) {
    if (!groupSlug) { throw "getEventsAttendance: received empty slug" }
    const apiURL = GET_GROUP_EVENT_ATTENDANCE.replace("{slug}", groupSlug)
    return axios.get(apiURL)
  },
  getGroupUnsummarizedEvents(groupSlug) {
    if (!groupSlug) { throw "getGroupUnsummarizedEvents: received empty slug" }
    const apiURL = `${UPDATE_GATEKEEPER_PROGRAM_GROUP_CONSUMERS_API_URL}${groupSlug}/unsummarized_events/`
    return axios.get(apiURL)
  },
  getStudentEventsAttendance(groupSlug, studentSlug) {
    if (!groupSlug) { throw "getEventsAttendance: received empty slug" }
    const params = { student_slug: studentSlug }
    const apiURL = GET_GROUP_EVENT_ATTENDANCE.replace("{slug}", groupSlug)
    return axios.get(apiURL, { params })
  },
  getProgramAttendance(programSlug, params) {
    if (!programSlug) { throw "getProgramAttendance: received empty slug" }
    const apiUri = `${EVENTS_URI}${programSlug}/get_activity_attendance_summary/`
    return axios.get(apiUri, { params })
  },
  getEventsConsumersAttendanceSummary(groupSlug) {
    if (!groupSlug) { throw "getEventsConsumersAttendanceSummary: received empty slug" }
    const apiURL = GET_GROUP_EVENT_COSUMER_ATTENDANCE_SUMMARY.replace("{slug}", groupSlug)
    return axios.get(apiURL)
  },
  getGroupPosts(groupSlug, params) {
    if (!groupSlug) { throw "getGroupPosts: received empty slug" }
    const apiURL = `${GET_GATEKEEPER_PROGRAM_GROUPS_API_URL}${groupSlug}/posts/`
    return axios.get(apiURL, { params })
  },
  addPostForGroup(groupSlug, data) {
    if (!groupSlug) throw "addPostForGroup: received empty groupSlug"
    return axios.post(`${GET_GATEKEEPER_PROGRAM_GROUPS_API_URL}${groupSlug}/add_post/`, data)
  },
}

export default gatekeeperProgramGroup
