
import Api from "@/api"

function getDefaultState() {
  return {
    highlightsList: [],
    totalHighlights: 0,
  }
}
const vxHighlights = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_HIGHLIGHTS_LIST(state, highlightsList) {
      state.highlightsList = highlightsList
    },
    SET_HIGHLIGHTS_TOTAL(state, total) {
      state.totalHighlights = total
    }
  },

  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },

    async loadHighlightsList({ commit, state, dispatch, rootGetters }, { otherParams = {} }) {
      const params = rootGetters["pagination/apiParams"]
      Object.assign(params, otherParams)
      const highlightsApiRes = await Api.apiHighlights.getHighlightsList(params)
      const highlightsList = highlightsApiRes.data["results"]
      const totalNumber = highlightsApiRes.data.count
      commit("SET_HIGHLIGHTS_TOTAL", totalNumber)
      dispatch("pagination/setTotalServerItems", totalNumber, { root: true })
      commit("SET_HIGHLIGHTS_LIST", highlightsList)
      return state.highlightsList
    },
  },
}

export default vxHighlights
