import axios from "axios"
import {
  GET_REPORT_TOTAL_EVENTS_BY_TAG,
  GET_REPORT_TOTAL_STUDENT_HOURS_BY_DAY_AND_TAG,
  GET_REPORT_EVENTS_API_URL,
} from "@/helpers/constants/constants"

const apiEventDashboard = {
  getTotalEventsByTag(params = {}) {
    return axios.get(GET_REPORT_TOTAL_EVENTS_BY_TAG, { params })
  },
  getTotalStudentHoursByDayAndTag(params = {}) {
    return axios.get(GET_REPORT_TOTAL_STUDENT_HOURS_BY_DAY_AND_TAG, { params })
  },
  getEventsMeasures(params = {}) {
    return axios.get(`${GET_REPORT_EVENTS_API_URL}measures/`, { params })
  }
}

export default apiEventDashboard
